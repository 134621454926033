import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'
import { useState } from 'react'
import { images } from 'assets'

const PasswordInput = ({
  name,
  label,
  placeholder,
  content = '',
  disabled = false,
  ariaLabel = '',
  onChange = () => {},
  onBlur = () => {},
  handleKeyPress = () => {},
  maxLength = 100,
  autoComplete = 'off'
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    register,
    formState: { errors }
  } = useFormContext()

  const viewPassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className={`input-group ${errors[name] && 'error'}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <div className='password-container'>
        <input
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          {...register(name, {
            onChange,
            onBlur
          })}
          aria-label={ariaLabel}
          onKeyPress={handleKeyPress}
          maxLength={maxLength}
          autoComplete={autoComplete}
          id={name}
          className={`border hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue ${errors[name] ? 'border-stroke-alert-red' : 'border-stroke-on-white-dark'}`}
        />
        <img
          aria-label={`${ariaLabel}-toggle`}
          onClick={viewPassword}
          alt='show/hide password'
          className='password-toggle'
          src={showPassword ? images['closed-eye'] : images['show-password']}
          role='button'
          tabIndex={0}
        />
      </div>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default PasswordInput
