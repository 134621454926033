import SignUpForm from './partials/SignUpForm'
import { FormProvider, useForm } from 'react-hook-form'
import SignInOAuthBtns from './partials/SignInOAuthBtns'
import { schema } from 'schemas/signUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { Mixpanel, MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'
import LinqtoCarousel from 'components/Global/LinqtoCarousel'
import { VariantOne, VariantTwo, VariantThree, VariantFour } from './Variants'
import { useEffect } from 'react'

const variation1CarouselData = [
  {
    id: 1,
    description: '“Linqto offers easy access to a diverse array of private companies, with a user-friendly platform and detailed research for informed investing. Highly recommended for diversifying into private equity.”',
    imageUrl: 'craig',
    name: 'Craig R.',
    copy: 'Member since 2022'
  },
  {
    id: 2,
    description: '"As a part-time angel investor, I value Linqto for its thorough due diligence, saving me precious time. It offers me access to pre-IPO opportunities in sectors I\'m interested in but have limited insight into."',
    imageUrl: 'cal',
    name: 'Cal M.',
    copy: 'Member since 2022'
  },
  {
    id: 3,
    description: '"Linqto has bridged the gap to make private equity more accessible and affordable with low minimums. This is my first time making private investments and Linqto customer support has provided a seamless experience!"',
    imageUrl: 'rebecca',
    name: 'Rebecca W.',
    copy: 'Member since 2023'
  }
]

const SignUpTest = ({
  oAuthToken,
  onOAuthSuccess,
  onSubmit,
  fromOrigination,
  setShowModal,
  submitLoading
}) => {
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { fromOrigination, oAuthToken } })
  const { signUpPageTest } = useLDFlags(['signUpPageTest'])
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE

  useEffect(() => {
    if (signUpPageTest) {
      Mixpanel.track('Sign Up Page Test', { variation: signUpPageTest })
    }
  }, [signUpPageTest])

  let variant = ''

  const control = (
    <>
      <p className='mt-12 sm:mt-32 mr-2.5 text-center heading_8_eyebrow text-text-secondary'>REAL PEOPLE, REAL EXPERIENCES</p>
      <p className='mb-0 text-center align-center giant_1 text-text-default'>Hear From<span className='text-center giant_1 text-text-hyperlink'>{' '}Linqto Members</span></p>
      <LinqtoCarousel data={variation1CarouselData} />
      <div className='mt-6 ml-auto mr-auto text-center text-black small_1 max-w-72'>These testimonials may not be representative of all client experiences; there is no guarantee you will share the same results.</div>
    </>
  )

  switch (signUpPageTest) {
  case 'Control':
    variant = control
    break
  case 'variation1':
    variant = <VariantOne />
    break
  case 'variation2':
    variant = <VariantTwo />
    break
  case 'variation3':
    variant = <VariantThree />
    break
  case 'variation4':
    variant = <VariantFour />
    break
  default: variant = control
  }

  if (isMobile) {
    return variant
  }

  return (
    <div className='bg-[#fafafa] flex h-[110vh]'>
      {signUpPageTest === 'Control' &&
        <img src={images['signup-graphic']} className='test-signup-graphic' alt='Sign up' />}
      <div className='relative w-1/2 text-center'>
        {!isMobile && variant}
      </div>
      <div className='z-10 test-form-container bg-background-default'>
        <h1 className='!mt-12 large_1 text-text-default justify-center'>Create Your Account</h1>
        <SignInOAuthBtns
          oAuthToken={oAuthToken}
          onOAuthSuccess={onOAuthSuccess}
        />
        <FormProvider {...methods}>
          <SignUpForm
            onSubmit={onSubmit}
            isFromOrigination={fromOrigination}
            setShowModal={setShowModal}
            submitLoading={submitLoading}
            oAuthToken={oAuthToken}
          />
        </FormProvider>
      </div>
    </div>
  )
}

export default SignUpTest
