import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import { seoTitleTemplate, Mixpanel, Braze, getDevice } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import * as BlinkIDSDK from '@microblink/blinkid-in-browser-sdk'
import { PageLoading } from 'components'
import Selfie from './partials/Selfie'
import IdentityConfirm from './partials/IdentityConfirm'
import MicroBlink from './partials/MicroBlink'
import { getIdentity, logEvent, restartKycProcess, confirmKycStatusVerified } from 'slices/kycSlice'
import { getCountries } from 'slices/commonSlice'
import DocumentType from './partials/DocumentType'

const AutoVerification = ({ match }) => {
  const [type, setType] = useState('')
  const [steps, setSteps] = useState('')
  const [idAllowed, setIdAllowed] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState('United States')
  const [pageLoading, setPageLoading] = useState(true)
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    const step = match.params.steps
    const prevType = query.get('type')
    dispatch(getIdentity())
      .then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          setPageLoading(false)
          if (payload?.isKycVerified) {
            history.push('/profile')
          } else {
            dispatch(getCountries())
            if (+step === 1) {
              dispatch(logEvent({ path: '/identityVerification/step1/idView' }))
            }
            setSteps(step)
            setType(prevType)
            if (+step !== 1) {
              history.push(`/identity/auto-verification/${step}?type=${prevType}`)
            }
            if (steps === '2' && type === '') {
              history.push('/identity')
            }
          }
        }
      })
  }, [])

  // NOTE: Replaced the old useEffect with the window reload, the old useEffect was causing issues with reinitiating the recognizers
  window.onpopstate = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (+steps === 1) {
      const data = ['Driver\'s License', 'Passport']
      dispatch(logEvent({ path: '/identityVerification/step1/idView', body: { ids: data } }))
    }
    if (+steps === 2) {
      dispatch(logEvent({ path: '/identityVerification/step2/microBlinkView' }))
    }
    if (+steps === 3) {
      dispatch(logEvent({ path: '/identityVerification/step3/selfieView' }))
    }
    if (+steps === 4) {
      dispatch(logEvent({ path: '/identityVerification/step4/confirmView' }))
    }
  }, [steps])

  const handleFinishScan = () => {
    dispatch(getIdentity())
    setSteps(3)
    Mixpanel.track('Onboarding Scan ID Success')
    history.push(`/identity/auto-verification/${3}?type=${type}`)
  }

  const handleConfirm = () => {
    Mixpanel.track('Onboarding Confirm Data from ID')
    dispatch(logEvent({ path: '/identityVerification/step4/confirmed' }))
    dispatch(confirmKycStatusVerified())
      .then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "mf_kyc_confirmed",
            type: 'Microblink'
          });
          Mixpanel.track('Onboarding Identity Verified')
          sessionStorage.setItem('identityVerified', true)
          history.push('/profile')
        }
      })
  }

  const handleRestart = () => {
    dispatch(logEvent({ path: '/identityVerification/step4/restart' }))
    Mixpanel.track('Onboarding Restart with New Document')
    dispatch(restartKycProcess())
  }

  const handleVerifySuccess = () => {
    dispatch(getIdentity())
      .then(() => {
        setSteps(4)
        history.push(`/identity/auto-verification/${4}?type=${type}`)
      })
  }

  const setIdType = (type) => {
    setType(type)
    setSteps(2)
    history.push(`/identity/auto-verification/${2}?type=${type}`)
    dispatch(logEvent({ path: '/identityVerification/step1/pickedIdType', body: { id: type === 'DL' ? 'Driver\'s License' : 'Passport' } }))
  }

  const selectIDType = (type) => {
    Mixpanel.track('Onboarding Choose ID Type', { 'ID Type': (type === 'DL') ? 'License' : type })
    setIdType(type)
  }

  const renderContent = () => {
    const isSupportedBrowser = BlinkIDSDK.isBrowserSupported()

    if (!isSupportedBrowser) {
      Mixpanel.track('Onboarding Error', { 'Error Type': 'Device or browser is not be supported' })
      Braze.track('Onboarding Error', { 'Error Type': 'Device or browser is not be supported', Device: getDevice() })
    }

    switch (steps.toString()) {
    case '1':
      return (
        <DocumentType selectIDType={selectIDType} idAllowed={idAllowed} setIdAllowed={setIdAllowed} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>
      )
    case '2': {
      if (isSupportedBrowser) {
        return <MicroBlink type={type} handleFinishScan={handleFinishScan} />
      } else {
        history.push('/identity/fail-submission')
        break
      }
    }
    case '3':
      return <Selfie handleRestart={handleRestart} handleVerifySuccess={handleVerifySuccess} />
    case '4':
      return <IdentityConfirm handleConfirm={handleConfirm} handleRestart={handleRestart} type={type}/>
    default: return ''
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Verify Identity')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Verify Identity')} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1>Verify Identity</h1>
          {renderContent()}
        </div>
      </div>
    </>
  )
}

export default AutoVerification
