import { createClient } from 'contentful'
import parse from 'html-react-parser'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { removeHtmlTags, replacePlaceholders } from 'utils'

/**
* Contentful environment configuration
* @typedef {Object} ContentfulKeys
* @property {string} space - Contentful space ID
* @property {string} accessToken - Contentful access token 
* @property {boolean} isDev - Whether in development environment
*/

/**
* Get Contentful configuration based on environment
* @returns {ContentfulKeys} Contentful configuration
*/
const getContentfulKeys = () => {
 if(window.location.hostname === 'app.linqto.com') {
   return {
     space: '9pn4c9ha4ow9',
     accessToken: 'pVuqWz8aQ3kSgAe6IeQGp5kd8vEcJrxsRcoJw_q-l2c',
     environment: 'master',
     isDev: false
   }
 } else {
   return {
     space: '9pn4c9ha4ow9', 
     accessToken: 'ArqLSNdDf5PJYzJ8bDWav4w7oq__Ppo-71tjVium_Bs',
     environment: 'beta',
     isDev: true
   }
 }
}

const { isDev, space, accessToken, environment } = getContentfulKeys()

/**
* Initialize Contentful client
* @type {import('contentful').ContentfulClientApi}
*/
const contentfulClient = createClient({
 space,
 accessToken,
 environment
})

/**
* Initialize Contentful preview client
* @type {import('contentful').ContentfulClientApi} 
*/
const contentfulPreviewClient = createClient({
 space,
 accessToken,
 environment,
 host: 'preview.contentful.com'

})

/**
* Get appropriate Contentful client based on environment
* @returns {import('contentful').ContentfulClientApi}
*/
const getClient = () => isDev ? contentfulPreviewClient : contentfulClient
const client = getClient()

/**
 * There exists a preview client and a production client. This is driven from configuration values provided at runtime
 *
 * There is an opportunity to save the client as a singleton after it has been initialized. This will
 * make it easier for usage in the future, instead of creating a new client everytime. This works for now
 * since we get all entries once at application startup. This is pending upon further developments on how we leverage contentful within the application.
 * @param {ContentfulConfig} config
 */
export const resetClient = (config) => {
  return createClient({
    space: config.getSpace(),
    accessToken: config.getAccessToken(),
    environment: config.getEnvironment(),
    host: config.getHost()
  })
}

/**
* Fetch all entries from Contentful
* @returns {Promise<Array<ContentfulEntry>>} Array of Contentful entries
*/
export const getEntries = async () => {
 try {
   const entries = await client.getEntries()
   return entries
 } catch (error) {
   console.error('Error fetching entries:', error)
 }
}

/**
* Fetch a single entry from Contentful
* @param {string} id - Entry ID
* @returns {Promise<ContentfulEntry>} Contentful entry
*/
export const getEntry = async (id) => {
 try {
   const entry = await client.getEntry(id)
   return entry
 } catch (error) {
   console.error('Error fetching entry:', error)
 }
}

/**
* Fetch an asset from Contentful
* @param {string} id - Asset ID
* @returns {Promise<Object>} Contentful asset
*/
export const getAsset = async (id) => {
 try {
   const asset = await client.getAsset(id)
   return asset
 } catch (error) {
   console.error('Error fetching asset:', error)
 }
}

/**
* @typedef {Object} RichTextContent
* @property {string} nodeType - Type of the rich text node
* @property {Object} data - Rich text data
* @property {Array} content - Rich text content array
*/

/**
* Process rich text content with placeholder substitution
* @param {RichTextContent} richText - Rich text content from Contentful
* @param {Object.<string, string>} placeholders - Key-value pairs of placeholders and their values
* @param {boolean} [removeHtml=true] - Whether to remove HTML tags
* @returns {React.ReactElement|null} Processed content as React element or null
*/
export const processRichText = (richText, placeholders = {}, removeHtml = true) => {
 if (!richText) return null
 
 try {
   const htmlContent = documentToHtmlString(richText)
   const content = removeHtml ? removeHtmlTags(htmlContent) : htmlContent
   let processedContent = content
   
   Object.entries(placeholders).forEach(([key, value]) => {
     processedContent = replacePlaceholders(processedContent, key, value)
   })
   
   return parse(processedContent)
 } catch (error) {
   console.error('Error processing rich text:', error)
   return null
 }
}

/**
* Get label content from items array
* @param {string} fieldId - Field identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @returns {string} Label content or empty string
*/
export const getLabelContent = (fieldId, items) => {
 return items.find(item => item.fields.id === fieldId)?.fields.label || ''
}

/**
* Get rich text content from items array
* @param {string} fieldId - Field identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @returns {RichTextContent} Rich text content or empty string
*/
export const getRichTextContent = (fieldId, items) => {
 const richText = items.find(item => item.fields.id === fieldId)?.fields.richText || ''
 return richText
}

/**
* @typedef {Object} BannerContent
* @property {string} id - Banner identifier
* @property {string} icon - Banner icon
* @property {React.ReactElement} text - Processed banner text
* @property {string} ctaLabel - Call to action label
*/

/**
* Get banner content with processed text
* @param {string} fieldId - Banner identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @param {Object.<string, string>} [placeholders={}] - Placeholder values
* @param {boolean} [removeHtml=true] - Whether to remove HTML tags
* @returns {BannerContent|null} Processed banner content or null
*/
export const getBannerContent = (fieldId, items, placeholders = {}, removeHtml = true) => {
 const bannerItem = items.find(item => item.fields.id === fieldId)
 if (!bannerItem?.fields) return null
 
 return {
   id: bannerItem.fields.id,
   icon: bannerItem.fields.icon,
   text: processRichText(bannerItem.fields.text, placeholders, removeHtml),
   ctaLabel: bannerItem.fields.ctaLabel
 }
}

/**
* @typedef {Object} ModalContent
* @property {string} id - Modal identifier
* @property {React.ReactElement} text - Processed modal text
* @property {string} title - Modal title
* @property {string} cta1Label - First call to action button label
* @property {string} cta2Label - Second call to action button label
*/

/**
* Get modal content with processed text
* @param {string} fieldId - Modal identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @param {Object.<string, string>} [placeholders={}] - Placeholder values
* @param {boolean} [removeHtml=true] - Whether to remove HTML tags
* @returns {ModalContent|null} Processed modal content or null
*/
export const getModalContent = (fieldId, items, placeholders = {}, removeHtml = true) => {
 const modalItem = items.find(item => item.fields.id === fieldId)
 if (!modalItem?.fields) return null
 
 return {
   id: modalItem.fields.id,
   text: processRichText(modalItem.fields.text, placeholders, removeHtml),
   title: modalItem.fields.title,
   cta1Label: modalItem.fields.cta1Label,
   cta2Label: modalItem.fields.cta2Label
 }
}

/**
* Get drawer content
* @param {object} fields - Drawer values
*/
export const getDrawerContent = (fields) => {
 return {
   icon: fields.icon,
   title: fields.title,
   body: fields.body,
   image: fields.image,
   primaryCta: { text: fields?.primaryCta?.fields?.text, url: fields?.primaryCta?.fields?.url },
   secondaryCta: { text: fields?.secondaryCta?.fields?.text, url: fields?.secondaryCta?.fields?.url },
 }
}

/**
* @typedef {Object} ButtonContent
* @property {string} id - Button identifier
* @property {string} label - Button label
* @property {string} ariaLabel - Button aria label for accessibility
*/

/**
* Get button content
* @param {string} fieldId - Button identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @returns {ButtonContent|null} Button content or null
*/
export const getButtonContent = (fieldId, items) => {
 const buttonItem = items.find(item => item.fields.id === fieldId)
 if (!buttonItem?.fields) return null
 
 return {
   id: buttonItem.fields.id,
   label: buttonItem.fields.label,
   ariaLabel: buttonItem.fields.ariaLabel
 }
}

/**
* @typedef {Object} TooltipContent
* @property {string} id - Tooltip identifier
* @property {string} name - Tooltip name
* @property {string} body - Tooltip body
* @property {string} tooltipTitle - Title of the tooltip
* @property {string} testId - Test identifier for testing
*/

/**
* Get tooltip content
* @param {string} fieldId - Tooltip identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @returns {TooltipContent|null} Tooltip content or null
*/
export const getTooltipContent = (fieldId, items, placeholders = {}, removeHtml = true) => {
  const tooltipItem = items.find(item => item.fields.id === fieldId)
  if (!tooltipItem?.fields) return null

  return {
    id: tooltipItem.fields.id,
    name: tooltipItem.fields.name,
    tooltipTitle: tooltipItem.fields.tooltipTitle,
    tooltipBody: processRichText(tooltipItem.fields.tooltipBody, placeholders, removeHtml),
    testId: tooltipItem.fields.testId
  }
}

/**
* Get image content
* @param {string} fieldId - Image identifier
* @param {Array<ContentfulEntry>} items - Array of content items
* @returns {Promise<ImageContent|null>} Image content or null
*/
export const getImageContent = async (fieldId, items) => {
  const imageItem = items.find(item => item.fields.id === fieldId)
  if (!imageItem?.fields) return null

  let mediaContent = null
  if (imageItem.fields.media?.sys?.id) {
    try {
      const mediaAsset = await getAsset(imageItem.fields.media.sys.id)
      if (mediaAsset?.fields) {
        mediaContent = {
          url: mediaAsset.fields.file?.url,
          title: mediaAsset.fields.title,
          description: mediaAsset.fields.description,
          file: mediaAsset.fields.file
        }
      }
    } catch (error) {
      console.error('Error fetching media asset:', error)
    }
  }
  
  return {
    id: imageItem.fields.id,
    name: imageItem.fields.name,
    media: mediaContent,
    testId: imageItem.fields.testId
  }
}