import { useEffect } from 'react'
import {
  Nav,
  Footer
} from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import Modal from './Global/Modal'
import ReactGA from 'react-ga4'
import { SEOvalues } from './data'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { getCoreUser, getFeatureFlags } from 'slices/userSlice'
import NotificationBanner from './Global/NotificationBanner'
import { Mixpanel, Braze, Fullstory } from 'utils'
import { setContentCards, setMixpanelExist, getContentfulData } from 'slices/commonSlice'
import { useQuery } from 'hooks/useQuery'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouteConfig } from '../routes/config'
import useAppMonitor from 'utils/monitoring/useAppMonitor.js'
import useLaunchDarkly from 'hooks/useLaunchDarkly'
import useBraze from 'utils/monitoring/Braze/useBraze.js'
import useConfig from '../config/useConfig.js'
import useContentful from 'hooks/useContentful.js'

const isProd = window.location.host === 'www.linqto.com' || window.location.host === 'app.linqto.com'
const queryClient = new QueryClient()

const App = () => {
  const {
    isImpersonating,
    firstName,
    lastName,
    canImpersonate,
    isSignedIn,
    isUserReturned,
    userId,
    userStage
  } = useSelector((state) => state.userSlice)
  const { isMixpanelExist, systemErr } = useSelector((state) => state.commonSlice)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const utmSource = query.get('utm_source')
  useLaunchDarkly(userId, userStage)

  const { configuration } = useConfig()
  useBraze(configuration, userId)
  useAppMonitor()
  useContentful(dispatch, getContentfulData, configuration)
  useEffect(() => {
    // Disable the auto scroll for browser & setting intial scroll to 0
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)
    dispatch(getCoreUser())
    // init Google Analytics GA4
    ReactGA.initialize([
      { trackingId: isProd ? 'G-3RQ2QB03RK' : 'G-JN2XXRMZXV', testMode: true }
    ])
    ReactGA.event('page_view', {
      page_location: window.location.pathname + window.location.search,
      page_title: document.title
    })

    history.listen(() => {
      ReactGA.event('page_view', {
        page_location: window.location.pathname + window.location.search,
        page_title: document.title
      })

      // setting scroll to the top when moving from page to page
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    })
  }, [])

  // Invest Feed Scroll - If not on products page, remove scroll from local storage (to reset scroll position)
  useEffect(() => {
    if (!location?.pathname?.includes('products')) {
      localStorage?.removeItem('investFeedScroll')
    }
  }, [location.pathname])

  useEffect(() => {
    let utmForZP
    if (utmSource === 'ZeroPark') {
      Fullstory.stopTracking()
      utmForZP = window?.location?.search
    }
    const notSignUpPage = location.pathname !== '/signup' && !isMixpanelExist
    const signUpPageNotZP = location.pathname === '/signup' && utmSource !== 'ZeroPark'
    // NOTE: this is to make sure ZP still being tracked on page other than sign up
    if (notSignUpPage || signUpPageNotZP) {
      Mixpanel.initialize()
      dispatch(setMixpanelExist())
      Fullstory.resumeTracking()
      if (utmForZP) {
        Mixpanel.registerUTM(utmForZP)
      }
    }
  }, [location.pathname, isMixpanelExist])

  useEffect(() => {
    if (systemErr) {
      Mixpanel.track('General Error', { 'Error Type': 'Internal Server Error' })
      Fullstory.track('General Error', { error_type: 'Internal Server Error' })
    }
  }, [systemErr])

  useEffect(() => {
    if (userId > 0) {
      Fullstory.identify(userId.toString())
    }
  }, [userId])

  useEffect(() => {
    const getFlags = async () => {
      if (isSignedIn) {
        await dispatch(getFeatureFlags())
      }
    }
    getFlags()
    if (Braze.isInitialized) {
      const brazeCards = Braze.retrieveContentCards()
      if (brazeCards && brazeCards.cards.length) {
        dispatch(setContentCards(brazeCards.cards))
      }
    }
  }, [isSignedIn])

  const showNav = location.pathname !== '/refer-friend'

  return (
    /** Note: Overflow hidden fixes width of this div on mobile devices  */
    <QueryClientProvider client={queryClient}>
      <Helmet>
        ‍<title>{SEOvalues.PAGE_TITLE}</title>
        <meta name='description' content={SEOvalues.PAGE_META_DESCRIPTION} />
        <meta name='keywords' content={SEOvalues.PAGE_KEYWORDS} />
      </Helmet>
      <div style={{ overflow: 'hidden' }}>
        {showNav && <Nav isUserReturned={isUserReturned}/>}
        {canImpersonate && isSignedIn && <NotificationBanner
          text={`Viewing As: ${isImpersonating ? `${firstName} ${lastName}` : 'Myself'}`}
          delay={0}
          fixed
          onClick = {() => { history.push('/ria-dashboard') }}
          actionLabel = {'See All Clients'}
          className='ria-banner'
        />
        }
        <RouteConfig />
        <Footer />
        {systemErr && (
          <Modal
            clickToClose={false}
            pressToClose={false}
            crossToClose={false}
            hideModal={() => console.log('err modal')}
            modalHeader='An Unexpected Error Occurred'
          >
            <div className='medium_1'>
              Please try refreshing the page or go back to the previous page.{' '}
              <br />
              You can also contact us at{' '}
              <a className='link text-text-hyperlink' href='mailto:support@linqto.com'>
              support@linqto.com
              </a>
              .
            </div>
          </Modal>
        )}
        <div id='chat-bot'></div>
      </div>
    </QueryClientProvider>
  )
}

export default App