import 'rc-slider/assets/index.css'
import { Wrapper, Content, ConditionalRender, Tooltip, PurchaseMessagingBanner } from 'components'
import Button from 'components/Global/Button'
import OrderDetails from './OrderDetails'
import { useState, useEffect } from 'react'
import PricingIndicatorModal from './PricingIndicatorModal'
import { useSelector, useDispatch } from 'react-redux'
import PlaceOrderHeader from './PlaceOrderHeader'
import Toggle from 'components/Global/Toggle'
import { toggleInterest } from 'slices/investSlice'
import { useHistory } from 'react-router-dom'
import { Mixpanel, Fullstory, MOBILE_WIDTH_SIZE, formatWholeNumber, formatDecimal } from 'utils'
import InCompleteProfileSection from './InCompleteProfileSection'
import NotifyMeCopy from './NotifyMeCopy'
import ScrollToTopBtn from './ScrollToTopBtn'
import InCompleteAccreditationSection from './InCompleteAccreditationSection'
import { useQuery } from 'hooks/useQuery'
import FinraCompleteProfileModal from './FinraCompleteProfileModal'
import { images } from 'assets'
import { useLDFlags } from 'utils/LaunchDarkly'
import useWindowSize from 'hooks/useWindowSize'
import InputPurchaseAmountKeypad from '../InputPurchaseAmount/InputPurchaseAmountKeypad'

const PlaceOrder = ({
  investAmount,
  setAmount,
  stickyHeader,
  setShares,
  shares,
  setSharePrice,
  sharePrice,
  totalTillNextTier,
  setTotalTillNextTear,
  totalSavings,
  setTotalSavings,
  isPreferred,
  setIsPreferred,
  tiers,
  setTiers,
  discountPercent,
  setDiscountPercent,
  discountedAmount,
  setDiscountedAmount,
  setShowQuestionsModal
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const query = useQuery()
  const promo = query.get('promo')
  const { inputPurchaseAmount, showWatchList } = useLDFlags(['inputPurchaseAmount', 'showWatchList'])
  const user = useSelector((state) => state.userSlice)
  const {
    featureFlags: { RetailPurchaseAllowed }
  } = user || {}
  const { details, isFinraUser, isFinraNotConfirmedUser, ownedShares } = useSelector((state) => state.investSlice)
  const [showTierModal, setShowTierModal] = useState(false)
  const [showHover, setShowHover] = useState(false)
  const [showInputKeypad, setShowInputKeypad] = useState(false)
  const [showFinraCompleteProfileModal, setShowFinraCompleteProfileModal] =
  useState(false)
  const [showingErrorBanner, setShowingErrorBanner] = useState(false)
  const {
    isSoldOut,
    promoFlag,
    companyId,
    name,
    sliderMinimum,
    urlName,
    discountNote,
    offers,
    isInterested,
    retailPurchaseEnabled,
    atsPoolEnabled
  } = details
  const { isPreferredCustomer } = user || {}
  const { isProfileComplete, isAccredited, isRetailPurchaser } =
  details?.user || {}
  const firstOfferShares = offers?.[0]?.shares || 0
  const isFirstOffer = firstOfferShares >= shares
  const disableBtn =
  (!isProfileComplete ||
    (!isAccredited && !isRetailPurchaser) ||
    (!isAccredited && !RetailPurchaseAllowed) ||
    (!isAccredited && !retailPurchaseEnabled)) &&
    !isFinraUser &&
    !isFinraNotConfirmedUser
  const isComingSoon = promoFlag === 'COMING_SOON'
  const placeOrderButtonText = (() => {
    if (!isProfileComplete) return 'Complete Profile'
    if (!isAccredited) return 'Verify Accreditation'
    if (isComingSoon) return 'Coming Soon'
    if (isSoldOut) return 'Unavailable'
    return 'Begin Order'
  })()
  const showSharePrice = (isAccredited && isProfileComplete) || isSoldOut
  // Show discount banner if inputPurchaseAmount flag is on and discount tiers exist (not sold out or coming soon)
  const showDiscountBanner = inputPurchaseAmount && !showingErrorBanner && !isComingSoon && !isSoldOut && isAccredited && isProfileComplete && offers?.[0]?.tiers?.some(tier => tier.sharePrice < details?.sharePrice && tier.minPurchase >= 10000)

  useEffect(() => {
      if (showInputKeypad && !isMobile && inputPurchaseAmount) {
        setShowInputKeypad(false)
      }
  }, [showInputKeypad, isMobile, inputPurchaseAmount])

  // NOTE: Passing the selected shares/amount is ugly now but lot of code will improve when we remove the slider logic
  const placeOrder = (selectedShares, e) => {
    e?.preventDefault()
    Mixpanel.register({ 'Team Apollo': isPreferredCustomer })
    Mixpanel.track('Place Order Started', {
      'Minimum investment amount': sliderMinimum,
      'Amount selected': investAmount,
      'Estimated shares': shares,
      'Company Name': name
    })
    Fullstory.track('Buy Order Start', { 
      minimum_investment_amount: sliderMinimum,
      amount_selected: investAmount,
      estimated_shares: shares,
      company_name: name
    })
    // If from mobile keypad, use keypad data, else use local state
    const amount = selectedShares?.cost ?? investAmount
    const estShares = selectedShares?.shares ?? shares
    history.push({
      pathname: '/orderconfirmation/cash',
      search: `?companyId=${companyId}&companyName=${name}&amount=${amount}&type=CASH&shares=${estShares}&minimumInvestmentAmount=${sliderMinimum}&urlName=${urlName}&discountedAmount=${discountedAmount}&discountNote=${discountNote}${
        promo ? `&promo=${promo}` : ''
      }`
    })
  }

  const handleChange = () => {
    Mixpanel.track('Click Notify Me on Product Page', {
      'Company Name': name,
      Toggle: !isInterested
    })
    dispatch(
      toggleInterest({ companyUrlName: urlName, isInterested: !isInterested })
    )
  }

  const handleGoToOrder = (e, tradeShares) => {
    if (isFinraUser || isFinraNotConfirmedUser) {
      setShowFinraCompleteProfileModal(true)
    } else tradeShares ? placeOrder(e, true) : placeOrder()
  }

  const handleButtonClick = (selectedShares) => {
    if(!isProfileComplete) {
      history.push('/profile')
      return
    }
    
    if (!isAccredited) {
      history.push('/investor-status')
      return
    }

    if (isFinraUser || isFinraNotConfirmedUser) {
      setShowFinraCompleteProfileModal(true)
    }
    
    if (isMobile) {
      setShowInputKeypad(true)
      return
    }
    else placeOrder(selectedShares)
  }

  const handelSell = () => {
    Mixpanel.track('Click Sell on Product Page', { 'Company Name': name })
    if (atsPoolEnabled) {
      history.push(`/sell-order/create/${urlName}`)
    }
  }

  return (
    <Wrapper className='product-order invest-order'>
      <ConditionalRender isVisible={inputPurchaseAmount && !!isMobile} className={`fixed bottom-0 left-0 z-50 block w-full ${ownedShares > 0 ? 'h-32' : 'h-20'} bg-background-default`}>
        {ownedShares > 0 && (
          <div className='flex flex-row items-baseline justify-between px-[26px]' style={{ boxShadow: ' 0px -2px -4px rgba(0, 0, 0, 0.15)' }}>
            <div className='flex flex-row'>
              <p className='mr-2 body_1 '>Shares Owned</p>
              <p className='body_2'>{formatWholeNumber(ownedShares)}</p>
            </div>
            <Button className='link text-text-hyperlink' onClick={handelSell}>
            Sell
            </Button>
          </div>
        )}
        <div className='border-t bg-background-default border-stroke-on-white-light'>
          {showSharePrice && (
            <div className='sticky-button-left'>
              <p className='body_1 '>Price per share</p>
              <p className={`body_2 ${isSoldOut ? 'blur-info' : ''}`}>{isSoldOut ? '$0.00' : `${formatDecimal(details?.sharePrice)}`}</p>
            </div>)}
          <div className={`fixed block ${showSharePrice ? 'right-[26px]' : 'w-[90%] right-[26px] left-[26px]'}`}>
            <Button
              size='lg'
              onClick={handleButtonClick}
              disabled={isSoldOut}
            >
              {placeOrderButtonText}
            </Button>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender isVisible={!inputPurchaseAmount || (inputPurchaseAmount && !isMobile)}>
        {isSoldOut && !showWatchList && (
          <Toggle
            label={`Notify me ${
              promoFlag === 'COMING_SOON' ? 'when' : 'if'
            } this becomes available.`}
            handleChange={handleChange}
            checked={isInterested}
            ariaLabel='notifyMeSwitch'
          />
        )}
        {showTierModal && tiers?.length >= 1 && isFirstOffer && (
          <PricingIndicatorModal
            hideModal={() => setShowTierModal(false)}
            tiers={tiers}
          />
        )}
        <Content className={(inputPurchaseAmount && !isMobile) ? 'flex flex-col items-end	' : ''}>
          {!inputPurchaseAmount && <PlaceOrderHeader sharePrice={sharePrice} />}
          {!isSoldOut && (
            <OrderDetails
              setAmount={setAmount}
              setShares={setShares}
              shares={shares}
              setSharePrice={setSharePrice}
              onTierPress={() => isFirstOffer && setShowTierModal(true)}
              totalTillNextTier={totalTillNextTier}
              setTotalTillNextTear={setTotalTillNextTear}
              totalSavings={totalSavings}
              setTotalSavings={setTotalSavings}
              isPreferred={isPreferred}
              setIsPreferred={setIsPreferred}
              tiers={tiers}
              setTiers={setTiers}
              isFirstOffer={isFirstOffer}
              discountPercent={discountPercent}
              setDiscountPercent={setDiscountPercent}
              discountedAmount={discountedAmount}
              setDiscountedAmount={setDiscountedAmount}
              handleButtonClick={handleButtonClick}
              showInputKeypad={showInputKeypad}
              setShowingErrorBanner={setShowingErrorBanner}
            />
          )}
          <ConditionalRender isVisible={!isSoldOut && !inputPurchaseAmount}>
            <Button
              customClass={`full-width ${stickyHeader ? 'sticky-content' : ''}`}
              onClick={handleGoToOrder}
              disabled={disableBtn}
            >
            Begin Order
            </Button>
          </ConditionalRender>
          <PurchaseMessagingBanner color='blue' isVisible={!!showDiscountBanner}>
            <div className='flex items-center'>
              <img src={images.tag} alt='tag' className='mr-2 ' />
              <p className='medium_1'>Spend <span className='medium_2'>$10k+</span> for lowest pricing.</p>
            </div>
            <Tooltip
              header='Preferred Pricing Discount'
              content={<p className='body_1'>Unlock bigger savings when you buy more. Our tiered pricing rewards you with lower costs per share as you invest more.
                <br /><br />
                - Spend <span className='body_2'>$10k+</span> to reach your first preferred pricing discount <br />
                - Spend <span className='body_2'>$100k+</span> to reach our top tier preferred pricing discount
              </p>}
              showHover={showHover}
              setShowHover={setShowHover}
            />
          </PurchaseMessagingBanner>
          {((!isFinraUser && !isFinraNotConfirmedUser) && !inputPurchaseAmount) && (
            <>
              <InCompleteProfileSection setShowQuestionsModal={setShowQuestionsModal} />
              <InCompleteAccreditationSection />
            </>
          )}
          {!showWatchList && <NotifyMeCopy />}
        </Content>
      </ConditionalRender>
      {showFinraCompleteProfileModal && (
        <FinraCompleteProfileModal
          hideModal={() => setShowFinraCompleteProfileModal(false)}
          actionAfterSave={placeOrder}
          showEmailAndPassword={isFinraNotConfirmedUser}
        />
      )}
      <ScrollToTopBtn stickyHeader={stickyHeader} />
      {showInputKeypad && inputPurchaseAmount && (
      <InputPurchaseAmountKeypad
        setShowInputKeypad={setShowInputKeypad}
        placeOrder={placeOrder}
      />
      )}
    </Wrapper>
  )
}
export default PlaceOrder
