import { useState } from 'react'
import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'

/**
 * @interface ContentfulFile
 * @property {Object} fields - Contentful fields container
 * @property {Object} fields.file - File information
 * @property {string} fields.file.url - URL of the file
 */

/**
 * @interface BannerContent
 * @property {string} id - Unique identifier for the banner
 * @property {Object} text - Rich text document object
 * @property {ContentfulFile} [icon] - Optional icon for the banner
 * @property {string} [ctaLabel] - Optional call to action label
 */

/**
 * @interface ModalContent
 * @property {string} [title] - Optional modal title
 * @property {Object} text - Rich text document object
 */

/**
 * @interface PriceHistoryChartBannerProps
 * @property {BannerContent} banner - Banner content and configuration
 * @property {ModalContent} modal - Modal content and configuration
 */

/**
 * Component that displays a price history chart banner with optional modal
 * 
 * @param {PriceHistoryChartBannerProps} props - Component props
 * @param {BannerContent} props.banner - Banner content and configuration
 * @param {ModalContent} props.modal - Modal content and configuration
 * @returns {React.ReactElement|null} Rendered component or null if required props are missing
 */
export const PriceHistoryChartBanner = ({ banner, modal }) => {
  if (!banner) return null
  const [showFootnoteModal, setShowFootnoteModal] = useState(false)
  return (
    <>
      <div
        data-testid={banner.id}
        className='flex items-center justify-center gap-4 px-6 py-4 mt-4 rounded-2xl bg-[#F9F9F9]'
      >
        {banner.icon?.fields?.file?.url && (
          <img
            src={banner.icon.fields.file.url}
            alt='Price History Chart Icon'
            className='w-5 h-5'
          />
        )}
        <div className='items-center inline text-gray-5 body_1'>
          {banner?.text}
          {banner?.ctaLabel && (
            <Button 
              className='ml-1 underline body_1 text-gray-5' 
              onClick={() => setShowFootnoteModal(true)}
            >
              {banner.ctaLabel}
            </Button>
          )}
        </div>
      </div>
      {showFootnoteModal && (
        <Modal
          crossToClose
          modalHeader={modal?.title}
          hideModal={() => setShowFootnoteModal(false)}
          innerStyle='text-left title-left'
        >
          <div className='medium_1 content'>
            {modal.text}
          </div>
        </Modal>
      )}
    </>
  )
}