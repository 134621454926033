import { useEffect, useState } from 'react'
import FooterLinks from './partials/FooterLinks'
import { images } from 'assets'
import { getDomain } from 'utils'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import ReactHtmlParser from 'react-html-parser'
import { useSelector } from 'react-redux'
import {contentfulContentIds} from 'components/data'

const socialLinksData = [
  { href: 'instagramLink', src: images.footer_instagram, alt: 'Linqto Instagram', testId: 'instagram-link' },
  { href: 'facebookLink', src: images.footer_facebook, alt: 'Linqto Facebook', testId: 'facebook-link' },
  { href: 'linkedinLink', src: images.footer_linkedin, alt: 'Linqto Linkedin', testId: 'linkedin-link' },
  { href: 'youtubeLink', src: images.footer_youtube, alt: 'Linqto Youtube', testId: 'youtube-link' },
  { href: 'twitterLink', src: images.footer_X, alt: 'Linqto X', testId: 'x-link' }
]

const SocialLinks = ({ footerEntry }) => {
  return (
    <div className='flex justify-center w-full mt-10 mb-10 md:justify-start'>
      {socialLinksData.map(({ href, src, alt, testId }) => (
        <a
          key={href}
          href={footerEntry[href]}
          target='_blank'
          rel='noreferrer'
          className='m-2 md:mr-3'
          data-testid={testId}
        >
          <img width={32} height={32} alt={alt} src={src} />
        </a>
      ))}
    </div>
  )
}

const Footer = () => {
  const { contentfulData } = useSelector((state) => state.commonSlice)
  const [footerEntry, setFooterEntry] = useState({})
  useEffect(() => {
    if (!contentfulData?.length) return
    const FooterData = contentfulData.find(
      item => item.sys.id === contentfulContentIds.footerId
    ) 
    if (FooterData?.fields) {
      setFooterEntry(FooterData.fields)
    }
  }, [contentfulData])

  
  const { footerTitle, footerDisclaimer, googlePlayLink, appleStoreLink, appleStoreLogo, googlePlayLogo, footerLogo } = footerEntry
  const htmlFooterDisclaimer = documentToHtmlString(footerDisclaimer)
  return (
    <div id='footer-div' className='bg-background-dark text-text-on-dark-bg p-10 md:p-20 lg:p-32 rounded-tl-[120px]'>
      <div className='flex flex-col w-full md:justify-between md:flex-row'>
        <div className='w-full md:w-2/3 md:pr-24'>
          <div className='flex-col text-text-default'>
            <a href={getDomain('/', true)} className='flex justify-center md:justify-start'>
              <img className='h-11' alt='Your Access to Private Investment' data-testid='footer-logo' src={footerLogo?.fields?.file?.url} />
            </a>
            <p className='text-text-on-dark-bg w-9/10 md:w-full mt-10 giant_1 text-center md:text-left lg:text-[64px] lg:leading-none'>
              {footerTitle}
            </p>
            <SocialLinks footerEntry={footerEntry} />
            <div className='flex flex-row justify-center w-full md:w-5/12 md:justify-start'>
              <a
                aria-label='Download Our App on Google Playstore'
                role='button'
                href={googlePlayLink}
                target='_blank'
                rel='noopener noreferrer'
                data-testid='google-play-link'
              >
                <img className='mr-2 h-11' data-testid='google-play-img' alt='Download Linqto app on Google Play' src={googlePlayLogo?.fields?.file?.url} />
              </a>
              <a
                aria-label='Download Our App on App Store'
                role='button'
                href={appleStoreLink}
                target='_blank'
                rel='noopener noreferrer'
                data-testid='apple-store-link'
              >
                <img className='ml-2 h-11' data-testid='apple-store-img' alt='Download Linqto app on App Store' src={appleStoreLogo?.fields?.file?.url} />
              </a>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2'>
          <FooterLinks footerEntry={footerEntry} />
        </div>
      </div>
      <div>
        <hr className='h-px my-16 border-0 bg-stroke-on-white-dark' />
        <div data-testid='copyrightCopy' className='body_1 text-text-placeholder'>
          &#169; {new Date().getFullYear()} LINQTO Inc.
        </div>
        <div data-testid='disclaimer-content' className='text-text-placeholder disclaimer-content'>
          {ReactHtmlParser(htmlFooterDisclaimer)}
        </div>
      </div>
    </div>
  )
}

export default Footer