import { useEffect } from 'react'
import Button from 'components/Global/Button'
import TextInput from 'components/Global/Inputs/TextInput'
import Checkbox from 'components/Global/Inputs/Checkbox'
import RadioButton from 'components/Global/Inputs/RadioButton'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import AddressInput from '../AddressInput'
import { getCountries } from 'slices/commonSlice'
import ConditionalRender from 'components/Global/ConditionalRender'
const FinancialAdvisorForm = ({ onSubmit, loading, renderVerificationPDF }) => {
  const dispatch = useDispatch()
  const { countries } = useSelector(state => state.commonSlice)
  const { showVerificationForm, financialAdvisorAttestation } = useSelector(state => state.financialAdvisor)
  const {
    handleSubmit,
    control,
    watch
  } = useFormContext()
  const country = watch('country')
  useEffect(() => {
    dispatch(getCountries())
  }, [])

  const renderAccreditationOptions = (options) => {
    return options.map((ele, id) => {
      return (
        <RadioButton
          name='optionId'
          value={ele.optionId}
          key={ele.optionId}
          disabled={loading}
          label={ele.optionText}
          ariaLabel={`optionId${id}`}
        />
      )
    })
  }

  return (
    <form className='grid-old' onSubmit={handleSubmit(onSubmit)}>
      <div className='eight wide tablet four computer column'>
        <TextInput
          name='firstName'
          label='First Name'
          disabled={loading}
          ariaLabel='First Name'
        />
      </div>
      <div className='eight wide tablet four computer column'>
        <TextInput
          name='lastName'
          label='Last Name'
          disabled={loading}
          ariaLabel='Last Name'
        />
      </div>
      <div className='eight wide tablet four computer column'>
        <TextInput
          name='occupation'
          label='Occupation'
          disabled={loading}
          ariaLabel='Occupation'
        />
      </div>
      <div className='eight wide tablet four computer column'>
        <TextInput
          name='companyName'
          label='Company'
          disabled={loading}
          ariaLabel='Company Name'
        />
      </div>
      <AddressInput
        disabled={loading}
        countries={countries}
        control={control}
      />
      <ConditionalRender isVisible={showVerificationForm}>
        <div className='sixteen wide column tablet_eight'>
          <div className='medium_1'>
            {financialAdvisorAttestation.description}
          </div>
        </div>
        <div className='radio-group'>
          {renderAccreditationOptions(financialAdvisorAttestation.options)}
          {country === 'United States' && (
            <div className='eight wide column tablet_eight'>
              <div className='small-space' />
              <TextInput
                name='licenseNumber'
                label=''
                disabled={loading}
                placeholder='License #'
                ariaLabel='License #'
              />
            </div>
          )}
        </div>
      </ConditionalRender>
      <div className='sixteen wide column'>
        <Button
          mode='secondary'
          type='button'
          onClick={renderVerificationPDF}
        >
          Generate Letter Preview
        </Button>
      </div>
      <Checkbox
        name='verificationConfirmed'
        ariaLabel='Confirm Verification Letter'
        disabled={loading}
        checkboxClass='fa-checkbox confirm-checkbox'
      >
        <div className='medium_1 text-text-default checkbox-content'>
          I confirm I have read the&#160;
          <a
            className='text-text-hyperlink inline-text-link'
            target='_blank'
            rel='noopener noreferrer'
            onClick={renderVerificationPDF}
          >
            verification letter.
          </a>
        </div>
      </Checkbox>
      <div className='sixteen wide column'>
        <div className='inline-flex mt-8'>
          <Button
            type='submit'
            loading={loading}
            disabled={loading}
          >
            Confirm Verification
          </Button>
        </div>
      </div>
    </form>
  )
}

export default FinancialAdvisorForm
