import { images } from 'assets'
import { Button, BottomPopupDrawer } from 'components'
import useWindowSize from 'hooks/useWindowSize'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { MOBILE_WIDTH_DRAWER_SIZE } from 'utils'

const Drawer = ({ visible, data, handleClose }) => {
  const { width } = useWindowSize()
  const history = useHistory()
  const { icon, title, body, image, primaryCta, secondaryCta } = data || {}
  const primaryCtaText = primaryCta?.text || ''
  const secondaryCtaText = secondaryCta?.text || ''
  const primaryCtaUrl = primaryCta?.url || ''
  const secondaryCtaUrl = secondaryCta?.url || ''
  const imageFile = image?.fields?.file?.url || ''
  const iconFile = icon?.fields?.file?.url || ''

  const handlePrimaryCta = () => {
    history.push(primaryCtaUrl)
    handleClose()
  }

  const handleSecondaryCta = () => {
    history.push(secondaryCtaUrl)
    handleClose()
  }

  let ModalDrawerView = (
    <div className="flex-col justify-between">
    <div className="flex justify-between mb-4">
      <div className="flex-col justify-start">
        {iconFile && <img className="mb-2 h-14 w-14" src={iconFile} alt='iconFileUrl' />}
        {imageFile && <img className='!my-5' src={imageFile} alt="MobileBottomPopup" />}
        <h6 className={`${imageFile ? 'mt-0' : 'mt-2'}`}>{title}</h6>
      </div>
    </div>
    <span className="medium_1">{body}</span>
    {primaryCtaText && <Button onClick={handlePrimaryCta} customClass={`!w-full ${!secondaryCtaText ? '!mb-4' : '!mb-0'}`}>{primaryCtaText}</Button>}
    {secondaryCtaText && <Button onClick={handleSecondaryCta} customClass="!w-full" mode="secondary">{secondaryCtaText}</Button>}
  </div>
  )

  if (width > MOBILE_WIDTH_DRAWER_SIZE) {
    ModalDrawerView = (
      <>
      <div className='flex items-center justify-between mb-4'>
        <div className='flex-col'>
          {iconFile && <img className='mb-2 h-14 w-14' src={iconFile} alt='iconFileUrl' />}
          <h6>{title}</h6>
        </div>
        <div onClick={() => handleClose(false)} className='flex items-center justify-center w-10 h-10 rounded-full cursor-pointer bg-gray1' data-testid='drawer-dismiss'>
          <img src={images.x} alt='xIcon' />
        </div>
      </div>
      <span className='medium_1'>{body}</span>
      {imageFile && <img className={`mt-6 ${primaryCtaText ? 'mb-12' : 'mb-4'}`} src={imageFile} alt='imageFileUrl' />}
      {primaryCtaText && <Button onClick={handlePrimaryCta} customClass='!w-full'>{primaryCtaText}</Button>}
      {secondaryCtaText && <Button onClick={handleSecondaryCta} customClass='!w-full' mode='secondary'>{secondaryCtaText}</Button>}
      </>
    )
  }

  return (
    <BottomPopupDrawer isOpen={visible} onClose={() => handleClose(false)}>
      {ModalDrawerView}
    </BottomPopupDrawer>
  )
}

export default Drawer
