import { images } from 'assets'
import { Wrapper, Image, Button } from 'components'
import useWatchListContentful from 'components/Invest/useWatchListContentful'
import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'

const WatchListButton = ({ 
  handleFavorite, 
  isInterested, 
  watchListAdd, 
  watchListRemove, 
  customClass 
}) => (
  <Button 
    ariaLabel='notifyBtn'
    size='sm' 
    mode='secondary' 
    onClick={handleFavorite}
    customClass={customClass}
  >
    <img 
      src={images[isInterested ? 'star-full' : 'star-empty']} 
      alt='star' 
      className='mr-2' 
    />
    {isInterested ? watchListRemove : watchListAdd}
  </Button>
)

const MobileInvestDetailHeader = (companyName, { handleFavorite, isInterested, watchListAdd, watchListRemove, watchListNotifyLabel }, showWatchList = false) => (
  <>
    {showWatchList && <>
      <p className='mt-4 medium_1'>{watchListNotifyLabel}</p>
      <WatchListButton handleFavorite={handleFavorite} isInterested={isInterested} watchListAdd={watchListAdd} watchListRemove={watchListRemove} customClass='!w-[80%]' />
    </>}
    <h3 className='company-details__subheader text-text-default'>Invest in {companyName}</h3>
  </>
)

const WebInvestDetailHeader = (vertical, founded, headquarters, website, { handleFavorite, isInterested, watchListAdd, watchListRemove, watchListNotifyLabel }, showWatchList = false) => (
  <>
    {showWatchList && <div className='mt-4'>
      <p className='mt-4 medium_1'>{watchListNotifyLabel}</p>
      <WatchListButton handleFavorite={handleFavorite} isInterested={isInterested} watchListAdd={watchListAdd} watchListRemove={watchListRemove} />
      </div>}
    <span className='medium_2 company-details__vertical text-text-secondary'>{vertical}</span>
    <div><span className='medium_2 text-text-secondary'>Founded:</span> <span className='medium_1 text-text-secondary'>{founded}</span></div>
    <div><span className='medium_2 text-text-secondary'>Headquarters:</span> <span className='medium_1 text-text-secondary'>{headquarters?.city}, {headquarters?.state}</span></div>
    <a target='_blank' rel='noopener noreferrer' href={`https://${website}`} className='medium_1 text-text-secondary'>{website}</a>
  </>
)

const InvestDetailHeader = ({ handleFavorite }) => {
  const watchListContentfulData = useWatchListContentful()
  const { showWatchList } = useLDFlags(['showWatchList'])
  const { watchListAdd, watchListRemove, watchListNotifyLabel } = watchListContentfulData || {}
  const { width } = useWindowSize()
  const { details } = useSelector(state => state.investSlice)

  const { founded, vertical, headquarters, website, name: companyName, isInterested } = details || {}

  let additionalCopy = ''

  if (width <= MOBILE_WIDTH_SIZE) {
    additionalCopy = MobileInvestDetailHeader(companyName, { handleFavorite, isInterested, watchListAdd, watchListRemove, watchListNotifyLabel }, showWatchList)
  } else {
    additionalCopy = WebInvestDetailHeader(vertical, founded, headquarters, website, { handleFavorite, isInterested, watchListAdd, watchListRemove, watchListNotifyLabel }, showWatchList)
  }

  return (
    <Wrapper className='invest-header'>
      <div className='company-available'>
        <div className='company-details'>
          <Image
            className='invest-logo'
            alt={details.name}
            src={details.logoUrl}
          />
          {additionalCopy}
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailHeader
