import { MOBILE_WIDTH_SIZE } from 'utils'
import { Button } from 'components'
import PropTypes from 'prop-types'
import useWindowSize from 'hooks/useWindowSize'
import { Thumbnail, ListItem } from 'components/Global/ProductItem'

const InvestSection = ({ title, list = [], listType = 'products', listStyle='thumbnail', hideTag='', verticals = [], onItemClick, onVectorSelect }) => {
  const { width } = useWindowSize()
  
  const maxVerticalCount = () => {
    if (width < 768) return 4;
    if (width < 1024) return 2;
    if (width < 1280) return 3;
    return 4
  }

  return (
    <div className='-mt-4 mb-14 sm:mt-4'>
      {title !== '' && <h2 className='mb-4 large_1 font-CodecColdBold' >{title}</h2>}
      {title === 'Companies' && width <= MOBILE_WIDTH_SIZE && <div className='flex gap-2 pr-6 mb-4 overflow-x-auto md:pr-0' role='group' aria-label='Filter sectors' style={{scrollbarWidth: 'none'}}>
        {verticals?.length > 0 && verticals?.slice(0, maxVerticalCount()).map((vertical, i) =>
          <Button
            className='px-4 rounded-[100px] bg-background-mid-gray flex-[0_0_auto] whitespace-nowrap font-CodecColdRegular h-10 md:h-8 text-sm'
            key={`vertical${i}`}
            data-testid={`vertical-${i}`}
            ariaLabel={`Filter by ${vertical}`} onClick={() => onVectorSelect(vertical)}>{vertical}</Button>
        )}
        <select
          onChange={(e) => onVectorSelect(e.target.value)}
          data-testid='sector-select'
          className='appearance-none rounded-[100px] bg-background-mid-gray font-CodecColdRegular border-0 hover:border-0 w-[110px] text-sm pr-0 h-10 md:h-8 text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue'
          aria-label='Filter by sectors'>
          <option value=''>Sectors</option>
          <option value='all'>All Sectors</option>
          {verticals && verticals.length > 0 && verticals.map((e, i) => <option value={e} key={`vertical-${i}`}>{e}</option>) }
        </select>
      </div>}
      <div className={`${title === 'Featured' ? 'grid grid-cols-2 md:flex md:flex-wrap' : 'flex flex-wrap'} min-w-full ${listStyle === 'thumbnail' ? 'sm:gap-7 gap-3' : 'gap-x-5 xl:gap-x-7 gap-y-5'}`}>
        {list.map((item, i) =>
          listStyle === 'thumbnail' ? <Thumbnail key={`list_${i}`} listType={listType} item={item} hideTag={hideTag} onItemClick={onItemClick} index={i} section={title} /> :   <ListItem key={`list_${i}`} item={item} onItemClick={onItemClick} index={i} section={title}/>
        )}
      </div>
    </div>)
}

InvestSection.propTypes = {
  listType: PropTypes.oneOf(['products', 'bundles']),
  listStyle:  PropTypes.oneOf(['thumbnail', 'list']),
  list:  PropTypes.array,
  title: PropTypes.string,
  hideTag: PropTypes.string,
  onItemClick: PropTypes.func
}

export default InvestSection

