import { useEffect, useState } from 'react'
import { images } from 'assets'
import { Button, Modal, PageLoading, TextInput } from 'components'
import Breadcrumb from 'components/Global/Breadcrumb'
import RadioButton from 'components/Global/Inputs/RadioButton'
import Select from 'components/Global/Inputs/Select'
import SeoMeta from 'components/Global/SeoMeta'
import TooltipLabel from 'components/Global/TooltipLabel'
import ProgressBar from 'components/Profile/partials/ProgressBar'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Mixpanel, seoTitleTemplate } from 'utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { entityAddressSchema, entityDetailsSchema } from 'schemas/addEntity'
import { useDispatch, useSelector } from 'react-redux'
import { getCountries, getRegions } from 'slices/commonSlice'
import { createEntity, getEntity } from 'slices/profileSlice'
import { useHistory, useParams } from 'react-router-dom'
import Upload from 'components/Global/Upload/Upload'
import ReactHtmlParser from 'react-html-parser'
import EntityTypeSelect from './EntityTypeSelect'
import { useQuery } from 'hooks/useQuery'

// FE/BE dropdown mapped values
const mappedEntityTypeDropdownOptions = {
  'S Corp': 'S_CORP',
  'C Corp': 'C_CORP',
  Partnership: 'PARTNERSHIP',
  'LLC Partnership': 'LLC_PARTNERSHIP',
  'LLC S Corp': 'LLC_S_CORP',
  'LLC C Corp': 'LLC_C_CORP',
  'Sole Proprietor': 'SOLE_PROPRIETOR',
  'Non-Profit Corporation or Endowment': 'NON_PROFIT_CORPORATION_OR_ENDOWMENT',
  'Revocable Trust': 'REVOCABLE_TRUST',
  'Irrevocable Trust': 'IRREVOCABLE_TRUST',
  Traditional: 'TRADITIONAL_IRA',
  Roth: 'ROTH_IRA',
  Sep: 'SEP_IRA',
  Other: 'OTHER'
}

// Reviewing Request Modal
const ReviewRequestModal = ({ hideModal, handleClick }) => (
  <Modal modalHeader='Reviewing Your Request' hideModal={() => hideModal(false)}>
    <div className='medium_1 content'>
      <p className='medium_2'>Your entity request was successfully received.</p>
      <p className='medium_1'>Our team will review your request right away. You&apos;ll receive an email when your entity has been approved.</p>
    </div>
    <div className='items-center ml-auto mr-auto md:inline-flex'><Button onClick={handleClick}>OK</Button></div>
  </Modal>
)

// Need Help link
const ContactLink = ({ isIRA }) => <p className='contact'>Need help? <a href={`/contact?topic=${isIRA ? 'Invest through a retirement account' : 'Invest through an entity'}&message=Re: Question about adding an ${isIRA ? 'IRA account' : 'entity'}`} className='medium_2 text-text-hyperlink'>Contact Us</a></p>

// Page Three
const UploadDocuments = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { entityId } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitErrorNoDocuments, setSubmitErrorNoDocuments] = useState(false)
  const { watch } = useFormContext()
  const { entity } = useSelector(state => state.profile)
  const { uploadedDocuments, entityTaxClassification } = entity || {}

  const investorOption = watch('investorOption')
  const entityDisplayType = watch('entityDisplayType')
  const country = watch('country')

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!uploadedDocuments || uploadedDocuments?.length < 1) {
      setSubmitErrorNoDocuments(true)
    } else {
      setSubmitErrorNoDocuments(false)
      setLoading(true)
      const res = await dispatch(createEntity({ entityId, status: 'PENDING_REVIEW' }))
      Mixpanel.track('Click Submit on Add Entity page', { Page: 'Upload Documents', 'Entity Type': investorOption, 'Enterprise Type': entityDisplayType, Country: country })

      setLoading(false)
      if (res?.payload?.status === 200) {
        setShowModal(true)
      }
    }
  }

  // on modal "Ok" click, hide modal and push to profile page
  const handleClick = () => {
    setShowModal(false)
    history.push('/profile?tab=entity')
  }

  // after user submits doc, reset error and call GET API to update list of documents
  const handleSuccessfulUpload = async () => {
    setSubmitErrorNoDocuments(false)
    await dispatch(getEntity(entityId))
  }

  let document1Copy = ''
  let document2Copy = ''

  if (entityTaxClassification === 'TRUST') {
    document1Copy = <p className='mb-2 medium_1'><span className='medium_2'>1) Trust Charter </span>evidencing discretionary power as an acting trustee.</p>
    document2Copy = <p className='medium_1'><span className='medium_2'>2) Advisor Letter</span> or <span className='medium_2'>Financial Documents</span> proving that the Trust has assets of $5,000,000 OR financial proof that all grantors are verified as accredited investors.</p>
  } else if (entityTaxClassification === 'NONE') {
    document1Copy = <p className='mb-2 medium_1'><span className='medium_2'>1) Onboarding Agreement, Articles of Incorporation</span> or equivalent documentations. We require this to understand the ownership structure and confirm your controlling interest in the company.</p>
    document2Copy = <p className='medium_1'><span className='medium_2'>2) Advisor Letter</span> or <span className='medium_2'>Financial Documents</span> proving that the entity has assets of $5,000,000 OR financial proof that all grantors are verified as accredited investors.</p>
  } else {
    document1Copy = <p className='mb-2 medium_1'><span className='medium_2'>1) Onboarding Agreement</span> or <span className='medium_2'>Articles of Incorporation</span> demonstrating your controlling interest in the company.</p>
    document2Copy = <p className='medium_1'><span className='medium_2'>2) Advisor Letter</span> or <span className='medium_2'>Financial Documents</span> proving that the entity has assets of $5,000,000 OR financial proof that all members are verified as accredited investors.</p>
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Add Entity')} />
      <div className='page-container wallet-container'>
        <div className='inner-container'>
          <Breadcrumb items={[{ name: 'Investor Profile', route: '/profile' }]} />
          <h1>Add Entity</h1>
          <ProgressBar activeStep='Verify' />
          <span className='large_1'>Upload Documents</span>
          <div className='add-entity-container'>
            <div className='add-entity-container__document-container'>
              <p className='mb-2 medium_2'>Please Upload The Following 2 Documents Below</p>
              {document1Copy}
              {document2Copy}
              {uploadedDocuments?.map(({ name, documentId }) => <Upload customClass='my-6' key={documentId} name={name} url='url' />)}
              <Upload
                callAfterSuccessUpload={handleSuccessfulUpload}
                entityId={entityId}
                type='ENTITY'
                allowDelete
                autoUpload
                customClass='manual-upload mt-4'
                acceptedFileFormat = '.png, .jpg, .jpeg, .pdf'
              />
              {submitErrorNoDocuments && <p className='medium_2 text-text-error'>Please upload the required documents.</p>}
              <p className='add-entity-container max-file-size-copy'>Maximum upload per file: 10MB</p>
            </div>
            <p className='large_1 add-entity-container acknowledgement'>Acknowledgement</p>
            <p className='add-entity-container submit-copy'>By clicking Submit below, I hereby certify that all investments completed on the Linqto platform via a corporate entity, trust, LLC, or any other type of entity can only be distributed, upon exit, to a brokerage account titled in the name of said entity. We will not be able to distribute your shares to a brokerage account titled in your personal name, or any name which does not match the name of the entity.</p>
            <div className='flex flex-col'>
              <div className='gap-4 mt-8 ml-0 md:ml-auto md:inline-flex'>
                <Button onClick={onSubmit} loading={loading}>Submit</Button>
              </div>
            </div>
            <ContactLink />
          </div>
        </div>
      </div>
      {showModal && <ReviewRequestModal setShowModal={setShowModal} handleClick={handleClick} />}
    </>
  )
}

// Page Two
const EntityAddress = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [regions, setRegions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { countries } = useSelector(state => state.commonSlice)
  const { handleSubmit, register, watch, setValue, getValues } = useFormContext()
  const country = watch('country')
  const state = watch('state')
  const { entity } = useSelector(state => state.profile)

  // if user refreshes on page 2, take them back to first page
  useEffect(() => {
    if (!getValues()?.investorOption) {
      history.replace('/entity')
    }
  }, [entity, getValues()])

  // reset region state based on country change (useEffect dependency) to populate State or Province dropdown
  /**
   * On "Next" button click - calls API that will send data object
   * @param {Object} data
   */
  const onSubmit = async (data) => {
    const { street1, street2, city, state, zip, country, entityDisplayType, entityTaxId, entityName, title, investorOption } = data || {}
    Mixpanel.track('Click Next on Add Entity page', { Page: 'Entity Address', 'Entity Type': investorOption, 'Enterprise Type': entityDisplayType, Country: country })
    // obj to send -- for entityDisplayType; map FE dropdown value to BE mapped values. IF investorOption radio button is OTHER, set entityType to OTHER
    let sentEntityDisplayType = mappedEntityTypeDropdownOptions[entityDisplayType]
    // if mapped value does not exist and user selects OTHER, set value to OTHER
    if (!sentEntityDisplayType && investorOption === 'OTHER') {
      sentEntityDisplayType = 'OTHER'
    }
    const obj = {
      entityDisplayType: sentEntityDisplayType,
      entityTaxId,
      entityName,
      title,
      entityAddress: { street1, street2, city, state, zip, country }
    }
    setLoading(true)
    const res = await dispatch(createEntity(obj))
    setLoading(false)
    // if payload is 200, show Reviewing Request modal and push to Upload Documents page
    if (res?.payload?.status === 200) {
      if (entityDisplayType === 'Roth' || entityDisplayType === 'Traditional' || entityDisplayType === 'Sep') {
        setShowModal(true)
      } else {
        history.push(`/entity/${res?.payload?.data?.entityId}?step=upload-documents`)
      }
    }
  }

  // when user changes country, call regions API with new value
  const handleSelectChange = async (e) => {
    setValue('state', '')
    const { payload } = await dispatch(getRegions(e))
    setRegions(payload || [])
  }

  // user goes back to first page
  const handleBack = (e) => {
    e.preventDefault()
    history.goBack()
  }

  // on modal "Ok" click, hide modal and push to profile page
  const handleClick = () => {
    setShowModal(false)
    history.push('/profile')
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Add Entity')} />
      <form onSubmit={handleSubmit(onSubmit)} className='page-container wallet-container'>
        <div className='inner-container'>
          <Breadcrumb items={[{ name: 'Investor Profile', route: '/profile' }]} />
          <h1>Add Entity</h1>
          <ProgressBar activeStep='finraProfile' />
          <span className='large_1'>Entity Address</span>
          <div className='add-entity-container'>
            <div className='add-entity-container__enterprise-container'>
              <TextInput name='street1' label='Address Line 1' ariaLabel='Street 1' />
              <TextInput name='street2' label='Address Line 2' ariaLabel='Street 2' />
              <TextInput name='city' label='City' ariaLabel='City'/>
              <TextInput name='zip' label='Postal Code' ariaLabel='Zip'/>
              <Select
                {...register('country')}
                options={countries}
                label='Country'
                ariaLabel='Country'
                defaultOption={{ value: country || '', label: country || 'Please select' }}
                handleChange={handleSelectChange}
              />
              {watch('country') && <Select
                {...register('state')}
                options={regions}
                label='State or Province'
                ariaLabel='State'
                defaultOption={{ value: state || '', label: state || 'Please select' }}
              />}
            </div>
            <div className='flex flex-col'>
              <div className='gap-4 mt-8 ml-0 md:ml-auto md:inline-flex'>
                <Button onClick={handleBack} mode='tertiary'>Back</Button>
                <Button loading={loading} type='submit' data-testid='nextToUpload'>Next</Button>
              </div>
              <ContactLink isIRA={watch('investorOption') === 'SELF_DIRECTED_IRA'} />
            </div>
          </div>
        </div>
      </form>
      {showModal && <ReviewRequestModal setShowModal={setShowModal} handleClick={handleClick} />}
    </>
  )
}

// Page One
const AddEntity = () => {
  const history = useHistory()
  const methods = useFormContext()
  const { watch, handleSubmit } = methods

  const investorOption = watch('investorOption')
  const entityDisplayType = watch('entityDisplayType')

  // renders Entity Address component
  const onSubmit = () => {
    history.push('/entity?step=entity-address')
    Mixpanel.track('Click Next on Add Entity page', { Page: 'Entity Details', 'Entity Type': investorOption, 'Enterprise Type': entityDisplayType })
  }

  // Entity Type Fields - different for each radio button
  let EntityTypeInputFields = ''
  if (investorOption === 'ENTERPRISE') {
    EntityTypeInputFields = (
      <div>
        <div className='border border-stroke-on-white-light add-entity-container box'>
          <img src={images.warning} alt='Warning Icon' />
          <span className='medium_1'>Please ensure that if your entity has multiple managing members, your entity meets one of these criteria: 1) your entity has $5 million in assets OR 2) everyone listed as owners of the entity is accredited.</span>
        </div>
        <div className='add-entity-container__enterprise-container'>
          <EntityTypeSelect
            name='entityDisplayType'
            options={['S Corp', 'C Corp', 'Partnership', 'LLC Partnership', 'LLC S Corp', 'LLC C Corp', 'Sole Proprietor', 'Non-Profit Corporation or Endowment']}
            label='Enterprise Type'
            ariaLabel='entityDisplayType' />
          <TextInput name='entityTaxId' label='Entity Tax ID' ariaLabel='Entity Tax ID' maxLength={25} />
          <TextInput name='entityName' label='Entity Name' ariaLabel='Entity Name'/>
          <TextInput name='title' label='Your Title Within The Entity' ariaLabel='Your Title Within The Entity' />
        </div>
      </div>
    )
  } else if (investorOption === 'TRUST') {
    EntityTypeInputFields = (
      <>
        <div className='add-entity-container__trust-container'>
          <EntityTypeSelect
            name='entityDisplayType'
            options={['Revocable Trust', 'Irrevocable Trust']}
            label='Trust Type'
            ariaLabel='entityDisplayType'
          />
          <TextInput name='entityTaxId' label='Entity Tax ID' maxLength={25} ariaLabel='Entity Tax ID' />
          <TextInput name='entityName' label='Entity Name' ariaLabel='Entity Name'/>
          <TextInput name='title' label='Your Title Within The Entity' ariaLabel='Your Title Within The Entity' />
        </div>
      </>
    )
  } else if (investorOption === 'SELF_DIRECTED_IRA') {
    EntityTypeInputFields = (
      <div>
        <div className='border border-stroke-on-white-light add-entity-container box'>
          <img src={images.warning} alt='Warning Icon'/>
          <span className='medium_1'>Please note that currently Linqto only work with Self-directed IRAs (SDIRA) at this time. When entering in your <span className='medium_2'>Entity Name</span> below use the format “[Your IRA Provider’s Name] FBO [Your Name] IRA [Your IRA account number]”.</span>
        </div>
        <div className='add-entity-container__enterprise-container'>
          <EntityTypeSelect
            name='entityDisplayType'
            options={['Traditional', 'Roth', 'SEP']}
            label='IRA Type'
            ariaLabel='entityDisplayType'
          />
          <div />
          <TextInput name='entityName' label='Entity Name' extraLabel=' (i.e IRA Club FBO John Doe IRA 123645)' />
          <TextInput name='entityTaxId' label='IRA Provider’s Tax ID' />
        </div>
      </div>
    )
  } else if (investorOption === 'OTHER') {
    EntityTypeInputFields = (
      <>
        <div className='add-entity-container__trust-container'>
          <TextInput name='entityTaxId' label='Entity Tax ID' maxLength={25} ariaLabel='Entity Tax Id' />
          <TextInput name='entityName' label='Entity Name' ariaLabel='Entity Name'/>
          <TextInput name='title' label='Your Title Within The Entity' ariaLabel='Title' />
          <div />
        </div>
      </>
    )
  }

  return (
    <FormProvider {...methods}>
      <SeoMeta title={seoTitleTemplate('Add Entity')} />
      <form onSubmit={handleSubmit(onSubmit)} className='page-container wallet-container'>
        <div className='inner-container'>
          <Breadcrumb items={[{ name: 'Investor Profile', route: '/profile' }]} />
          <h1>Add Entity</h1>
          <ProgressBar />
          <span className='large_1'>Entity Details</span>
          <div className='add-entity-container'>
            <div className='add-entity-container__radio-container'>
              <span className='medium_2'>What is your Entity type?</span>
              <div className='radio-group'>
                <div className='flex-justify-start'>
                  <RadioButton className='pointer medium_1' name='investorOption' value='ENTERPRISE' label='Enterprise (e.g. LLCs, S Corps, C Corps, etc.)' />
                  <TooltipLabel label='' content={ReactHtmlParser('<div>According to the SEC, Enterprises qualify as accredited if: <ul class="tooltip-list-container"><li>All of the equity owners are accredited investors OR</li> <li>The enterprise was not formed for the specific purpose of acquiring the securities offered and owns investments in excess of $5,000,000</li></ul></div>')} />
                </div>
                <div className='flex-justify-start'>
                  <RadioButton className='pointer medium_1' name='investorOption' value='TRUST' label='Trust (e.g. Revocable, Irrevocable, etc.)' />
                  <TooltipLabel label='' content={ReactHtmlParser('<div>According to the SEC,<br />Trusts qualify as accredited if: <ul class="tooltip-list-container"><li>If it has total assets in excess of $5,000,000</li> <li>Was not formed for the specific purpose of investing in the securities being considered for purchase and purchase is being directed by a sophisticated person</li></ul></div>')} />
                </div>
                <RadioButton className='pointer medium_1' name='investorOption' value='SELF_DIRECTED_IRA' label='Self-directed IRA' />
                <RadioButton className='pointer medium_1' name='investorOption' value='OTHER' label='Other (e.g. International equivalent of LLC, etc.)' />
              </div>
            </div>
            {EntityTypeInputFields}
            {!!investorOption && <div className='flex flex-col'>
              <div className='gap-4 mt-8 ml-0 md:ml-auto md:inline-flex'>
                <Button disabled mode='tertiary'>Back</Button>
                <Button type='submit'>Next</Button>
              </div>
              <ContactLink isIRA={investorOption === 'SELF_DIRECTED_IRA'} />
            </div>}
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

// Entry Point for 3 pages
const EntityForm = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const { entityId } = useParams()
  const step = query.get('step')
  const [loading, setLoading] = useState(true)
  // different schema based on which page the user is on (0 = Entity Details, 1 = Entity Address)
  const methods = useForm({ resolver: yupResolver(!step ? entityDetailsSchema : entityAddressSchema) })
  const { reset, watch } = methods
  const investorOption = watch('investorOption')

  // get countries API (to populate address dropdown)
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCountries())
      if (entityId) {
        await dispatch(getEntity(entityId))
      }
      setLoading(false)
    }
    fetchData()
  }, [entityId])

  // reset fields (unregister) if user changes radio button option
  useEffect(() => {
    const enterpriseTrustSelfDirectedIraFields = { entityDisplayType: '', entityTaxId: '', entityName: '', title: '' }
    const otherFields = { entityTaxId: '', entityName: '', title: '' }
    if (investorOption !== 'OTHER') {
      reset({ investorOption, ...enterpriseTrustSelfDirectedIraFields })
    } else if (investorOption === 'OTHER') {
      reset({ investorOption, ...otherFields })
    }
  }, [investorOption])

  if (loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Entity Details')} />
        <PageLoading />
      </>
    )
  }

  if (!step) {
    // no first step, render add-entity page
    return (
      <FormProvider {...methods}>
        <AddEntity />
      </FormProvider>
    )
  } else if (step === 'entity-address') {
    // render second page
    return (
      <FormProvider {...methods}>
        <EntityAddress />
      </FormProvider>
    )
  } else if (step === 'upload-documents' || !!entityId) {
    // render third page
    return (
      <FormProvider {...methods}>
        <UploadDocuments />
      </FormProvider>
    )
  }
}

export default EntityForm
