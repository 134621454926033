import { images } from 'assets'
import Button from 'components/Global/Button'
import PageLoading from 'components/Global/PageLoading'
import SeoMeta from 'components/Global/SeoMeta'
import { useQuery } from 'hooks/useQuery'
import useWindowSize from 'hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getReferAFriend, getSignupReferral } from 'slices/userSlice'
import { MOBILE_WIDTH_SIZE, seoTitleTemplate, Mixpanel } from 'utils'
import ReferSplashForm from './ReferSplashForm'
import ReferralError from 'components/OnBoarding/SignUp/partials/ReferralError'
import ReferFriendCopy from 'components/OnBoarding/SignUp/partials/ReferFriendCopy'

const ReferSplash = () => {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const referralCode = query.get('r') || null
  const [loading, setLoading] = useState(true)
  const [referralError, setReferralError] = useState(false)
  const [referralErrorMessage, setReferralErrorMessage] = useState(null)

  const {
    signUpBonus,
    referrerName,
    isSignedIn,
    isUserReturned
  } = useSelector((state) => state.userSlice)

  useEffect(() => {
    (async () => {
      await dispatch(getReferAFriend(referralCode || ''))
      Mixpanel.track('View Referral Page')
      setLoading(false)
    }
    )()
  }, [referralCode])

  useEffect(() => {
    (async () => {
      if (isUserReturned) {
        if (isSignedIn) {
          history.push('/products')
        } else {
          getSignupPage()
        }
      }
    })()
  }, [isUserReturned, isSignedIn])

  const closeErrorModal = () => {
    setReferralError(false)
    if (referralErrorMessage?.action === 'redirect') {
      history.push('/signup')
    } else if (referralErrorMessage?.action === 'redirectToSignIn') {
      history.push('/products')
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const getSignupPage = async () => {
    const res = await dispatch(getSignupReferral({ referralCode, referralType: 'linqto-referral' }))
    const { meta, payload } = res

    if (meta?.requestStatus === 'fulfilled') {
      const ReferralByAnotherUser = payload?.hasReferrer && !payload?.isRiaReferral
      const ReferredByAnAdvisor = payload?.hasReferrer && payload?.isRiaReferral
      const ReferralCredit = payload?.signupBonus || 0
      Mixpanel.register({ 'Referral by another user': ReferralByAnotherUser, 'Referred by an advisor': ReferredByAnAdvisor, 'Referral credit': ReferralCredit, Platform: 'Web' })
      Mixpanel.track('View Referral Page')
      if (!payload?.hasReferrer) {
        if (payload?.notifyMessage) {
          setReferralError(true)
          setReferralErrorMessage({
            message: payload?.notifyMessage,
            title: 'Referral Link Error',
            actionLabel: 'OK',
            action: 'redirectToSignIn'
          })
        } else if (referralCode) {
          setReferralError(true)
          setReferralErrorMessage({
            message: "The referral link has expired or the referrer doesn't exist.",
            title: 'Something Went Wrong',
            actionLabel: 'Continue to sign up',
            action: 'redirect'
          })
        }
      }
    }
  }

  let step1, step4 = ''

  if (width <= MOBILE_WIDTH_SIZE) {
    step1 = images['image-company-mobile']
    step4 = images['image-minimums-mobile']
  } else {
    step1 = images['image-company-desktop']
    step4 = images['image-minimums-desktop']
  }

  if (loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Refer a Friend')} ogImage={`${window.location.origin}/linqto_refer_a_friend.png`}/>
        <PageLoading />
      </>
    )
  }

  return (
    <>
      {referralError && (
        <ReferralError
          referralErrorMessage={referralErrorMessage}
          closeModal={closeErrorModal}
        />
      )}
      <SeoMeta title={seoTitleTemplate('Refer a Friend')} ogImage={`${window.location.origin}/linqto_refer_a_friend.png`}/>
      <img className='logo-2' alt='Linqto - Private Investing' src={images['logo-white']} />
      <img alt='Linqto - Private Investing' className='refer-friend-splash' src={width <= MOBILE_WIDTH_SIZE ? images['image-splash-mobile'] : images['image-splash-desktop']} />
      <div className='page-container'>
        <div className='inner-container refer-friend'>
          <div className='flex-col'>
            <div className='box refer-friend__info-container'>
              <h4 className='text-text-default'>Your <span className='giant_1 text-text-hyperlink source-serif-4'>${signUpBonus} Linqto Bucks</span> is waiting for you</h4>
              <p className='refer-friend__info-container__mt-12 text-text-default'>
                {referrerName} would like you to join the over 750k investors getting early access to private investments.
              </p>
              <ReferSplashForm referralCode={referralCode}/>
            </div>
          </div>
          <div className='refer-friend__steps'>
            <span className='giant_2 text-text-default'>Why are investors <span className='giant_2 text-text-hyperlink source-serif-4'>choosing Linqto?</span></span>
            <div className='refer-friend__steps__one'>
              <h2 className='text-logo-mint'>#1</h2>
              <h6 className='large_1 text-text-default'>Early access to a diverse array of dynamic private companies and prominent unicorns</h6>
              <span>80+ Companies Available</span>
              <img className='refer-friend__steps__one__one-img refer-friend__mt-12' src={step1} alt='80+ Companies Available' />
            </div>
            <div className='refer-friend__steps__one'>
              <h2 className='text-logo-mint'>#2</h2>
              <h6 className='large_1 text-text-default'>Multiple exits to date with several more exciting developments on the horizon</h6>
              <div className='refer-friend__steps__one__img-container'>
                <img className='refer-friend__mt-12' src={images.coinbase} alt='Coinbase'/>
                <img className='refer-friend__mt-12' src={images.sofi} alt='Sofi'/>
                <img className='refer-friend__mt-12' src={images.robinhood} alt='Robinhood'/>
              </div>
            </div>
            <div className='refer-friend__steps__one'>
              <h2 className='text-logo-mint'>#3</h2>
              <h6 className='large_17 text-text-default'>750K+ users across 110 countries have invested over $400 Million on our platform</h6>
              <img className='refer-friend__steps__one__three-img' src={images['image-world']} height={300} alt='750K+ users'/>
            </div>
            <div className='refer-friend__steps__one'>
              <h2 className='text-logo-mint'>#4</h2>
              <h6 className='large_1 text-text-default'>Low minimum investment of $5,000</h6>
              <span>As low as $2,500 for select companies</span>
              <img className='refer-friend__steps__one__four-img refer-friend__mt-12' src={step4} alt='Low minimum investment of $5,000' />
            </div>
            <div className='refer-friend__steps__one'>
              <h2 className='text-logo-mint'>#5</h2>
              <h6 className='large_1 text-text-default'>True liquidity in private market investing</h6>
              <span>We are the first platform to offer real-time settlements, enabling our investors to seamlessly buy or sell their holdings.</span>
              <img className='refer-friend__steps__one__five-img refer-friend__mt-12' src={images['image-liquidity']} alt='True liquidity' />
            </div>
          </div>
        </div>
      </div>
      <div className='refer-friend__howto'>
        <div className='refer-friend__howto__wrapper'>
          <div className='giant_2 text-text-default'>How to claim your <span className='giant_2 text-text-hyperlink'>${signUpBonus}?</span></div>
          <ReferFriendCopy />
        </div>
      </div>
      <div className='bg-gradient-citron-turq refer-friend__bottom'>
        <h3 className='text-text-default'>Your access to private investment</h3>
        <div>
          <Button mode='secondary' ariaLabel={`Claim your ${signUpBonus}`} onClick={scrollToTop}>Claim your ${signUpBonus}</Button>
        </div>
      </div>
    </>
  )
}

export default ReferSplash
