import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageLoading, Checkbox, Button, EmailInput } from 'components'
import { Mixpanel, Fullstory, getDomain, TABLET_WIDTH_SIZE, useLDFlags } from 'utils'
import { confirmFinraUser, getCoreUser, loginUnconfirmedUser } from 'slices/userSlice'
import { images } from 'assets'
import * as yup from 'yup'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { getFinraUserStatus } from 'slices/investSlice'
import useWindowSize from 'hooks/useWindowSize'

const SignUpFinra = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { landingPageAi } = useLDFlags(['landingPageAi'])
  const query = location.search
  const userClaimId = qs.parse(query, { ignoreQueryPrefix: true })?.userClaimId || null
  const code = qs.parse(query, { ignoreQueryPrefix: true })?.code || null
  const { isSignedIn } = useSelector((state) => state.userSlice)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(
        'Please enter a password.'
      ).test(
        'len',
        'Password must be at least 8 characters long.',
        (val) => val.length > 7
      ).test('containsNum', 'Password must contain at least one number.', (val) =>
        val?.match(/\d+/g)
      )
  })

  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { } })
  const { handleSubmit, reset } = methods
  useEffect(() => {
    const runExperiment = async () => {
      if (landingPageAi === 'land_on_signup') {
        setPageLoading(true)
        const { payload } = await dispatch(getFinraUserStatus())
        setPageLoading(false)
        if (payload) {
          reset(payload)
        }
      }
      if (landingPageAi === 'land_on_invest') {
        history.push('/products')
      }
    }
    if (isSignedIn) {
      runExperiment()
    }
  }, [landingPageAi, isSignedIn])

  useEffect(() => {
    const loginUser = async () => {
      const { meta, payload } = await dispatch(loginUnconfirmedUser({ userClaimId, code }))
      setPageLoading(false)
      if (meta?.requestStatus === 'fulfilled') {
        await dispatch(getCoreUser())
      }
      if (meta?.requestStatus === 'rejected' && payload === 'UNCONFIRMED_USER_ALREADY_CONFIRMED') {
        history.push('/signin')
      }
    }
    loginUser()
    Mixpanel.track('View Finra Sign Up Page', { userClaimId })
    Fullstory.track('Page View', {
      page_name: 'View Finra Sign Up Page',
      user_claim_id: userClaimId
    })
  }, [])

  const onSubmit = async (data) => {
    setSubmitLoading(true)
    const bodyTosend = {
      code,
      userClaimId,
      ...data
    }
    const { meta } = await dispatch(confirmFinraUser(bodyTosend))
    setSubmitLoading(false)
    if (meta.requestStatus === 'fulfilled') {
      await dispatch(getCoreUser())
      Mixpanel.track('Finra Sign Up Success', { userClaimId })
      Fullstory.track('Finra Sign Up Success', { user_claim_id: userClaimId })
      history.push('/products')
    }
  }

  if (pageLoading) {
    return (
      <PageLoading />
    )
  }

  const imageName = width > TABLET_WIDTH_SIZE ? 'finra-company' : 'finra-company-mobile'
  const imageWidth = width > TABLET_WIDTH_SIZE ? '400px' : '90%'

  return (
    <>
      <div className='lg:flex lg:min-h-[105vh] lg:bg-[#fafafa] block py-10 lg:py-0 lg:mb-0 mb-[600px] w-full'>
        <div className='relative flex-col w-full text-center lg:w-1/2'>
          <p className='lg:mt-[130px] lg:mb-2 heading_8_eyebrow text-text-secondary'>Invest In Top Private Companies</p>
          <h3 className='mt-0 text-text-default'>Welcome to <span className='giant_1 text-text-hyperlink'>{' '}Linqto</span></h3>
          <p className='medium_1 px-5 lg:px-[20%]'>We are revolutionizing private market investing by allowing you early access to invest in disruptive tech companies, at the lowest minimums in the market.</p>
          <div className='mt-4 lg:mb-16 flex justify-between lg:px-[25%] px-12 mb-10'>
            <div className='flex-col justify-between'>
              <p className='flex justify-center large_1 text-text-default'>750K<img src={images['plus-sign-fresh-mint']} className='mt--1 ml-[1px] align-middle' alt='Linqto Members'/></p>
              <p className='medium_1 text-text-secondary '>Linqto <br />Members</p>
            </div>
            <div className='flex-col justify-between'>
              <p className='flex justify-center large_1 text-text-default'>$390M<img src={images['plus-sign-fresh-mint']} className='mt--1 ml-[1px] align-middle' alt='Total Investments'/></p>
              <p className='medium_1 text-text-secondary'>Total <br />Investments</p>
            </div>
            <div className='flex-col justify-between'>
              <p className='flex justify-center large_1 text-text-default'>50<img src={images['plus-sign-fresh-mint']} className='mt--1 ml-[1px] align-middle' alt='Companies Available'/></p>
              <p className='medium_1 text-text-secondary'>Companies <br />Available</p>
            </div>
          </div>
          <h6 className='text-text-default'>Our popular companies</h6>
          <img src={images[imageName]} width={imageWidth} alt='Our popular companies' className='my-5 ml-auto mr-auto'/>
        </div>
        <div className='flex-col justify-center items-center absolute right-0 lg:w-1/2 w-full min-h-[105vh] bg-background-default rounded-tl-[40px] rounded-bl-[40px] border border-stroke-on-white-light finra-form'>
          <h6 className='text-text-default'>Explore available investments</h6>
          <p className='medium_1'>Create an account and start your journey</p>
          <div className='p-4 lg:pt-0'>
            <FormProvider {...methods}>
              <form className='grid max-w-[466px]' onSubmit={handleSubmit(onSubmit)} noValidate>
                <EmailInput
                  name='email'
                  label='Email'
                  ariaLabel='email'
                  disabled
                />
                <PasswordInput
                  name='newPassword'
                  label='Password'
                  ariaLabel='password-input'
                  disabled={submitLoading}
                  autoComplete='new-password'
                />
                <Checkbox
                  label='I would like to receive updates on new investment opportunities and market insights'
                  name='marketingOptIn'
                  ariaLabel='marketingOptIn'
                  checkboxClass='marketing-checkbox'
                />
                <div className='mt-9 text-text-default'>
                By clicking &quot;Start Investing&quot; below, I agree to the{' '}
                  <a href={getDomain('/terms-of-use', true)} rel='noopener noreferrer' className='text-text-hyperlink inline-text-link' target='_blank'>Linqto Terms of Use</a>, <a className='text-text-hyperlink inline-text-link' target='_blank' rel='noopener noreferrer' href={getDomain('/privacy', true)}>Privacy Policy</a> and <a className='text-text-hyperlink inline-text-link' target='_blank' rel='noopener noreferrer' href={getDomain('/crs', true)}>Form CRS</a>.

                  <div className='flex'>
                    <Button
                      type='submit'
                      loading={submitLoading}
                      disabled={submitLoading}
                      customClass='full-width'
                    >
                      Join Now
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpFinra
