import { useEffect } from 'react'
import qs from 'qs'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { verifyEmail } from 'slices/userSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { PageLoading } from 'components'

const Verify = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { pageLoading } = useSelector(state => state.userSlice)
  useEffect(() => {
    const info = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    // add custom tracking for google ads
    window.gtag('event', 'conversion', {
      send_to: 'AW-1057610699/2waLCL_tmuUDEMu3p_gD'
    })
    dispatch(verifyEmail(info))
      .then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "mf_sign_up_successful"
          });
          if (payload?.redirectUrl) {
            history.push(payload.redirectUrl)
          } else {
            history.push('/profile')
          }
        }
      })
  }, [])

  if (pageLoading) return <PageLoading />

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Email Verified')} />
      <div className='page-container'></div>
    </>)
}

export default Verify
