import { useEffect } from 'react'

/**
 *
 * @param dispatch
 * @param getContentfulData
 * @param {Config} configuration
 */
function useContentful(dispatch, getContentfulData, configuration) {
  useEffect(() => {
    const contentfulConfig = configuration.getContentfulConfig()
    dispatch(getContentfulData({ config: contentfulConfig }))
  }, [configuration])
}

export default useContentful