import rg4js from 'raygun4js'
import env from '../env'
import AppMonitorConfig from './AppMonitor.config'
import useRaygunFullStory from '../RaygunFullStory'
import { Fullstory } from 'utils'

/**
 * @interface AppMonitorInterface
 * @property {function(): void} init - Initializes the monitoring system.
 * @property {function(User): void} setUser - Sets the user information for monitoring.
 * @property {function(string): void} setAppVersion
 * @property {function(string): void} onPageView
 */

/**
 *
 * @type AppMonitorInterface
 */
const noop = {
  init: () => {
  },
  setUser: () => {
  },
  setAppVersion: () => {
  },
  onPageView: () => {
  }
}

/**
 *
 * @type AppMonitorInterface
 */
const monitor = {
  init: () => {
    Fullstory.initialize()
    rg4js('apiKey', AppMonitorConfig.apiKeys[env.getEnv()].key)
    rg4js('enableCrashReporting', true)
    rg4js('enableRealUserMonitoring', true)
    rg4js('ignore3rdPartyErrors', true)
    useRaygunFullStory(rg4js);
  },
  /**
   *
   * @param {User} user
   */
  setUser: (user) => {
    // https://raygun.com/documentation/language-guides/javascript/advanced-setup/#customers
    rg4js('setUser', {
      identifier: user.getUserId(),
      uuid: user.getUserId(),
      isAnonymous: false
    })
  },
  setAppVersion: (version) => {
    rg4js('setVersion', version)
  },
  onPageView: (pathname) => {
    // https://raygun.com/documentation/language-guides/javascript/real-user-monitoring/react/#react-router
    rg4js('trackEvent', { type: 'pageView', path: pathname })
  }
}

/**
 * Local is disabled to allocate more events to non-local environments.
 * @type AppMonitorInterface
 */
const AppMonitor = AppMonitorConfig.isMonitoringEnabled()
  ? monitor
  : noop

export default AppMonitor