import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button, Tooltip, SeoMeta, PageLoading } from 'components'
import { Thumbnail } from 'components/Global/ProductItem'
import api from 'apis/private.js'
import { formatDecimal, formatWholeNumber, seoTitleTemplate, MOBILE_WIDTH_SIZE } from 'utils'
import useWindowSize from 'hooks/useWindowSize'
import { handleServerError } from 'slices/commonSlice'
import { useDispatch } from 'react-redux'

const OrderSuccess = ({ order, amountToCashAccount }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderId, shares, amount, seriesName, company } = order || {}
  const { name, iconUrl } = company || {}
  const { data: companies, isLoading } = useQuery({
    queryKey: ['featuredList'],
    queryFn: () => getFeaturedCompanies(),
    select: (data) => data?.companies ?? [],
    onError: (error) => console.error('Failed to fetch featured companies:', error)
  })

  const getFeaturedCompanies = async () => {
    try {
      const response = await api.get('/resource/featuredCompanies')
      return response.data
    } catch (error) {
      dispatch(handleServerError(error))
    }
  }
  const topFourFeaturedCompanies = useMemo(() => companies?.slice(0, 4), [companies])
  const [showHover, setHover] = useState(false)
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE

  const navigateToPortfolio = () => history.push('/portfolio')
  
  const navigateToInvest = () => history.push('/products')
  
  const selectFeaturedCompany = (company) => history.push(`/products/${company?.urlName}`)
  
  const navigateToLinqtoBucks = () => history.push('/rewards')

  if (isLoading) {
    return (
      <PageLoading />
    )
  }
  
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
      <div className='relative flex flex-col items-center'>
      <video 
        src={`${window.location.origin}/wave-animation-${isMobile ? 'mobile': 'desktop'}.mp4`} 
        width='100%' 
        height='500' 
        autoPlay
        loop 
        controls={false} 
        muted
      />
        <div className='absolute bottom-[-48px] md:bottom-[-62px] flex items-center justify-center w-24 h-24 rounded-full md:w-[124px] md:h-[124px] bg-background-default'>
          <img className='flex w-20 h-20 rounded-full md:w-24 md:h-24' src={iconUrl} alt={`${name} icon url`} />
        </div>
      </div>
      <div className='page-container buy-order-container'>
        <div className='inner-container'>
          <div className='flex flex-col text-center'>
            <span className='mt-3 medium_1 text-text-secondary'>Order ID {orderId}</span>
            <span className='mt-5 large_1'>Order Success</span>
            <span className='mt-3 self-center w-[267px] md:w-full'>Congratulations! {name} has been added to your portfolio.</span>
          </div>
          <div className='p-4 mt-4 border rounded-lg border-gray-3 md:max-w-[540px] md:mx-auto'>
            <div className='flex flex-row justify-between'>
              <span className='medium_1'>{name}</span>
              <Button onClick={navigateToPortfolio} className='medium_2 text-text-hyperlink'>View My Portfolio</Button>
            </div>
            <span className='flex mt-3 medium_1 text-text-secondary'>{seriesName}</span>
            <div className='flex flex-row justify-between mt-3'>
              <span className='medium_1'>Shares</span>
              <span className='medium_2'>{formatWholeNumber(shares)}</span>
            </div>
            <div className='flex flex-row justify-between mt-3'>
              <span className='medium_1'>Investment Amount</span>
              <span className='medium_2'>{formatDecimal(amount)}</span>
            </div>
          </div>
          {amountToCashAccount > 0 && <div className='p-4 mt-4 border rounded-lg border-gray-3 lg: max-w-[540px] lg:mx-auto'>
            <div className='flex flex-row justify-between'>
              <span>
                Cash Balance Returned
                <Tooltip
                  header={'Cash Balance Returned'}
                  content={'Your transaction will reflect only whole shares. Any leftover value will be returned to your cash balance.'}
                  showHover={showHover}
                  setShowHover={setHover}
                />
              </span>
              <span className='medium_2'>{formatDecimal(amountToCashAccount)}</span>
            </div>
          </div>}
        </div>
        <div className='md:max-w-6xl md:mx-auto'>
          <div className='flex justify-between my-5'>
            <span className='medium_2'>Featured</span>
            <Button onClick={navigateToInvest} className='medium_2 text-text-hyperlink'>View All</Button>
          </div>
          <div className={`${isMobile ? 'grid grid-cols-2' : 'flex flex-wrap'} min-w-full sm:gap-7 gap-3`}>
            {topFourFeaturedCompanies?.map((item, i) =>
              <Thumbnail key={`list_${i}`} listType={'products'} item={item} onItemClick={selectFeaturedCompany} index={i} section={'Featured'} />
            )}
          </div>
        </div>
        <div className='relative overflow-hidden w-full md:max-w-6xl md:mx-auto mx-0 my-[60px] pt-[40px] pr-0 pb-[136px] md:pb-0 pl-[35px] border border-stroke-on-white-light rounded-tl-2xl rounded-br-2xl rounded-tr-[80px] rounded-bl-2xl'>
          <img className='absolute right-0 top-10 md:top-0' src={`${window.location.origin}/refer-friend-bg-${isMobile ? 'mobile': 'desktop'}.png`} alt='refer-friend-bg' />
          <div className='flex flex-col'>
            <span className={`${isMobile ? 'medium_2':'large_1'}`}>Refer a Friend,</span>
            <span className={`${isMobile ? 'giant_1': 'giant_3'}`}>Get $1,000</span>
            <span className={`${isMobile ? 'medium_2':'large_1'} mb-7`}>in Linqto Bucks*</span>
            <Button className='w-[135px] h-12 bg-fill-cobalt-primary rounded-[30px]' onClick={navigateToLinqtoBucks}>
              <span className='medium_2 text-text-on-dark-bg'>Learn More</span>
            </Button>
            <span className='my-5 small_1 max-w-[45%]'>*Can be used for up to 10% of your next investment</span>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrderSuccess