import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Mixpanel, formatNumberWithLetter } from 'utils'
import { Button } from 'components'

const InformationContainer = ({ isSummary = false, setMosaicShowModal }) => {
  const { details, linqtoImpliedValuation } = useSelector(state => state.investSlice)
  const { impliedValuation, totalRaised, postMoney, lastRound, industry, mosaic, vertical, employeeCount, name, isSoldOut, promoFlag } = details || {}
  const { amount, round, date } = lastRound || {}
  const { management, market, momentum, money, overall } = mosaic || {}
  const { industry: primaryIndustry } = industry || {}

  const handleClick = () => {
    setMosaicShowModal(true)
    Mixpanel.track('Click What is a Mosaic Score? on Product Page', { 'Company Name': name })
  }

  if (!isSummary) {
    return (
      <div className='border border-stroke-on-white-light column eight row valuation-block min-w-[320px] shrink-[2]'>
        <div className='valuation-block__top'>
          {round && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Last Round</span>
            <span className='medium_2 text-wrap'>{moment.utc(date).format('MMM. YYYY')}, {round}</span>
          </div>}
          {postMoney && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Valuation Post-Money</span>
            <span className='medium_2'>{formatNumberWithLetter(postMoney, true, true, 2, true)}</span>
          </div>}
          {amount && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Amount Raised</span>
            <span className='medium_2'>{formatNumberWithLetter(amount, true, true, 2, true)}</span>
          </div>}
          {totalRaised && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Total Funds Raised</span>
            <span className='medium_2'>{formatNumberWithLetter(totalRaised, true, true, 2, true)}</span>
          </div>}
        </div>
        <div className='border-t border-t-stroke-on-white-light valuation-block__bottom'>
          <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary margin-bottom'>Implied Valuation</span>
            <span className='text-logo-turq valuation-block__valuation margin-bottom'>{(impliedValuation && !isSoldOut) || (promoFlag === 'COMING_SOON' && impliedValuation) ? linqtoImpliedValuation : 'TBD'}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='border column eight row valuation-block border-stroke-on-white-light'>
      <div className='valuation-block__top'>
        {primaryIndustry && <div className='valuation-block__info'>
          <span className='body_1 text-text-secondary'>Industry</span>
          <span className='medium_2'>{primaryIndustry}</span>
        </div>}
        {vertical && <div className='valuation-block__info'>
          <span className='body_1 text-text-secondary'>Primary Vertical</span>
          <span className='medium_2'>{vertical}</span>
        </div>}
        {employeeCount && <div className='valuation-block__info'>
          <span className='body_1 text-text-secondary'>Employees</span>
          <span className='medium_2'>{employeeCount}</span>
        </div>}
      </div>
      {overall && <div className='border-t border-t-stroke-on-white-light valuation-block__bottom'>
        <div className='valuation-block__container'>
          <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary margin-bottom'>Mosaic Score</span>
            <span className='text-logo-turq valuation-block__valuation'>{overall}</span>
          </div>
          <div className='valuation-block__right'>
            <div className='valuation-block__info'>
              <span className='body_1 text-text-secondary'>Money</span>
              <span className='medium_2'>{money}</span>
            </div>
            <div className='valuation-block__info'>
              <span className='body_1 text-text-secondary'>Momentum</span>
              <span className='medium_2'>{momentum}</span>
            </div>
            <div className='valuation-block__info'>
              <span className='body_1 text-text-secondary'>Management</span>
              <span className='medium_2'>{management}</span>
            </div>
            <div className='valuation-block__info'>
              <span className='body_1 text-text-secondary'>Market</span>
              <span className='medium_2'>{market}</span>
            </div>
          </div>
        </div>
        <Button className='mt-6 mb-3 medium_2 text-text-hyperlink' onClick={handleClick}>What is a Mosaic Score?</Button>
      </div>
      }
    </div>
  )
}

export default InformationContainer
