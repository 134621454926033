import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { schema } from 'schemas/withdrawShema'
import { yupResolver } from '@hookform/resolvers/yup'
import { getWithdrawPage, commitWithdrawRequest, commitUpholdWithdrawRequest } from 'slices/walletSlice'
import FBOWithdrawHeader from './partials/FBOWithdrawHeader'
import FBOWithdrawForm from './partials/FBOWithdrawForm'
import FBOWithdrawCommitErrorModal from './partials/FBOWithdrawCommitErrorModal'

const FBOWithdraw = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const query = location.search
  const entityId = qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null

  const { availableWithdrawBalance, upholdAccessId } = useSelector((state) => state.walletSlice)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const methods = useForm({ mode: 'all', resolver: yupResolver(schema), context: { availableWithdrawBalance, selectedAccount } })

  useEffect(() => {
    const fetchWithdrawPage = async () => {
      await dispatch(getWithdrawPage(entityId))
      setIsLoading(false)
      Mixpanel.track('View Withdraw Funds Page')
    }
    fetchWithdrawPage()
  }, [])

  const onSubmit = async (formValues) => {
    // TODO: refactor the loading state so its local and not from redux
    const formAmount = formValues.amount.charAt(0) === '$' ? formValues.amount.substring(1) : formValues.amount
    try {
      let response
      if (selectedAccount.method === 'Uphold') {
        response = await dispatch(commitUpholdWithdrawRequest({ upholdAccessId: upholdAccessId, cardId: selectedAccount.id, transferAmount: formAmount }))
      } else {
        response = await dispatch(commitWithdrawRequest({ cashExternalAccountId: selectedAccount.id, transferAmount: formAmount }))
      }
      if (response?.meta?.requestStatus === 'fulfilled') {
        handleSubmitSuccess(formValues)
      } else {
        Mixpanel.track('Withdrawal Error', { 'Error Type': response?.error })
        setShowErrorModal(true)
      }
    } catch (error) {
      console.log('Withdraw request error', error)
    }
  }

  const handleSubmitSuccess = (formValues) => {
    Mixpanel.track('Withdrawal Request Successful', { Amount: formValues.amount, Method: selectedAccount.method })
    localStorage?.setItem('withdraw-funds', true)
    history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
  }

  const handleSelectAccount = account => {
    setSelectedAccount(account)
    Mixpanel.track('Select Withdraw Account', { Method: account.method })
  }

  if (isLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Withdraw Funds')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Withdraw Funds')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          <FBOWithdrawHeader />
          <div className='border border-stroke-on-white-light add-funds-container'>
            <FormProvider {...methods}>
              <FBOWithdrawForm
                onSubmit={onSubmit}
                selectedAccount={selectedAccount}
                setSelectedAccount={handleSelectAccount}
                entityId={entityId}
              />
            </FormProvider>
          </div>
        </div>
      </div>
      {showErrorModal && <FBOWithdrawCommitErrorModal hideModal={ () => setShowErrorModal(false) } />}
    </>
  )
}

export default FBOWithdraw
