import SignUpForm from './partials/SignUpForm'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import SignInOAuthBtns from './partials/SignInOAuthBtns'
import { schema } from 'schemas/signUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { images } from 'assets'
import { useState } from 'react'
import ReferralHowItWorksModal from './partials/ReferralHowItWorksModal'
import ReferFriendCopy from './partials/ReferFriendCopy'
import { formatDecimal } from 'utils'

const SignUpReferFriend = ({
  oAuthToken,
  onOAuthSuccess,
  onSubmit,
  fromOrigination,
  submitLoading
}) => {
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { fromOrigination, oAuthToken } })
  const { signupBonus } = useSelector((state) => state.userSlice)
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className='signup-with-shadow-container'>
        <img src={images['signup-graphic']} className='signup-with-shadow-graphic' alt='Sign up' />
        <div className='signup-with-shadow-copy-container'>
          <div className='refere-friend-copy-wrapper'>
            <div className='giant_2 text-text-default'>
              You’re one step closer <span className='giant_2 text-text-hyperlink'>to {formatDecimal(signupBonus, true, 0)}</span>
            </div>
            <div className='mt-4 refer-friend-link'>
              <a className='text-text-hyperlink inline-text-link' onClick={() => setShowModal(true)}>See how it works</a>
              <div className='mt-4 text-text-default medium_1'>Let&apos;s get started by creating your account. </div>
            </div>
            <ReferFriendCopy />
          </div>
        </div>
        <div className='bg-background-default signup-with-shadow-form-container'>
          <div className='large_1 text-text-default'>Create An Account</div>
          <SignInOAuthBtns
            oAuthToken={oAuthToken}
            onOAuthSuccess={onOAuthSuccess}
          />
          <div className='form-inner-container'>
            <FormProvider {...methods}>
              <SignUpForm
                onSubmit={onSubmit}
                isFromOrigination={fromOrigination}
                setShowModal={setShowModal}
                submitLoading={submitLoading}
                oAuthToken={oAuthToken}
              />
            </FormProvider>
          </div>
        </div>
      </div>
      {showModal && <ReferralHowItWorksModal closeModal={ () => setShowModal(false)} />}
    </>
  )
}

export default SignUpReferFriend
