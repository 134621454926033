import { images } from 'assets'
import AccreditationHeader from './AccreditationHeader'

const UnderReviewStatusView = ({ accreditationTypeData, accreditationSubTypeData }) => {
  const { ICON: typeIcon, TITLE: typeTitle } = accreditationTypeData || {}
  const { ICON: methodIcon, TITLE: methodTitle } = accreditationSubTypeData || {}
  return (
    <div className='accreditation-under-review'>
      <AccreditationHeader />
      <div className='border border-stroke-on-white-light accreditation-under-review__box margined'>
        <div className='flex-justify-start__mobile-column'>
          <div className='large_1'>Accreditation Method </div>
          <div className='status-tag body_1 text-icon-orange'><img width={16} height={16} src={images['acc-under-review']} alt='Accreditation Under Review' />Under Review</div>
        </div>
        <div className='flex-justify-start__mobile-column'>
          {typeTitle && <div className='accreditation-type flex-align-center'>
            <img className='type-icon' src={images[typeIcon]} alt={typeTitle} />
            <div className='medium_2'>{typeTitle}</div>
          </div>
          }
          <div className='accreditation-type flex-align-center'>
            <img className='type-icon' src={images[methodIcon]} alt={methodTitle} />
            <div className='medium_2'>{methodTitle}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderReviewStatusView
