import { contentfulContentIds } from 'components/data';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLabelContent, processRichText } from 'utils';

const CONTENT_FIELDS = {
  WATCH_LIST_TITLE: 'watchListTitle',
  WATCH_LIST_COPY: 'watchListCopy',
  WATCH_LIST_NOTIFY_LABEL: 'watchListNotifyLabel',
  WATCH_LIST_ADD: 'watchListAdd',
  WATCH_LIST_REMOVE: 'watchListRemove',
}

const useWatchListContentful = () => {
  const [watchListContentfulData, setWatchListContentfulData] = useState(null)
  const { contentfulData = [] } = useSelector(state => state.commonSlice)

  useEffect(() => {
    const watchListArray = contentfulData.filter(item => item.sys.id === contentfulContentIds.watchListId) || []
    const items = watchListArray?.[0]?.fields?.items || []
    // rich text field
    const watchListCopy = items.find(item => item.fields.id === CONTENT_FIELDS.WATCH_LIST_COPY)
    setWatchListContentfulData({
      watchListTitle: getLabelContent(CONTENT_FIELDS.WATCH_LIST_TITLE, items), 
      watchListCopy: processRichText(watchListCopy?.fields?.richText, '', false),
      watchListNotifyLabel: getLabelContent(CONTENT_FIELDS.WATCH_LIST_NOTIFY_LABEL, items),
      watchListAdd: getLabelContent(CONTENT_FIELDS.WATCH_LIST_ADD, items),
      watchListRemove: getLabelContent(CONTENT_FIELDS.WATCH_LIST_REMOVE, items),
    })
  }, [contentfulData])

  return watchListContentfulData
}

export default useWatchListContentful
