import { images } from 'assets'
import SlideCarousel from 'components/Global/SlideCarousel'
import { useSelector } from 'react-redux'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const VariantOne = () => {
  const { width } = useWindowSize()
  const { aiCompanies } = useSelector((state) => state.userSlice)
  const isMobile = width <= MOBILE_WIDTH_SIZE

  return (
    <>
      <div className='flex flex-col items-center center'>
        <p className='mt-12 text-center large_1 mb-10 sm:mb-14 sm:mt-20 max-w-[284px] sm:max-w-[448px]'>
          Invest early in AI - the disruptor of the global economy.
        </p>
        <img
          alt='ai disruptor graph'
          src={images['ai-disruptor-graph']}
          className='max-w-[270px] sm:max-w-[385px]'
        />
        {isMobile && <p className='medium_1 mt-[100px] mb-5 max-w-[545px]'>
          The generative AI market is set for explosive growth, expected to expand from $40 billion in 2022 to $1.3 trillion over the next decade, with a projected CAGR of 42%. -Bloomberg, June 2023
        </p>}
        <p className='medium_2 mt-9 sm:mt-[100px] mb-12 text-center'>
          Build a diversified portfolio of private AI investments
        </p>
        <SlideCarousel companies={aiCompanies} />
        {!isMobile && <p className='medium_1 mt-[100px] mb-5 max-w-[545px] sm:mb-32'>
          The generative AI market is set for explosive growth, expected to expand from $40 billion in 2022 to $1.3 trillion over the next decade, with a projected CAGR of 42%. -Bloomberg, June 2023
        </p>}
      </div>
    </>
  )
}

const VariantTwo = () => {
  const { aiCompanies } = useSelector((state) => state.userSlice)

  return (
    <>
      <div className='flex flex-col items-center center'>
        <p className='mt-12 text-center large_1 mb-10 sm:mb-14 sm:mt-20 max-w-72 sm:max-w-[448px]'>
          Join our Community
        </p>
        <div className='grid grid-flow-col grid-rows-2 gap-6'>
          <div className='flex flex-col justify-center w-40 text-center border h-28 sm:w-64 border-stroke-on-white-light rounded-2xl'>
            <span className='giant_1 text-text-default'>800k+</span>
            <span className='medium_4 text-text-default'>Registered Users</span>
          </div>
          <div className='flex flex-col justify-center w-40 text-center border h-28 sm:w-64 border-stroke-on-white-light rounded-2xl'>
            <span className='giant_1 text-text-default'>80+</span>
            <span className='medium_4 text-text-default'>Companies Offered</span>
          </div>
          <div className='flex flex-col justify-center w-40 text-center border h-28 sm:w-64 border-stroke-on-white-light rounded-2xl'>
            <span className='giant_1 text-text-default'>$450M+</span>
            <span className='medium_4 text-text-default'>Total Investments</span>
          </div>
          <div className='flex flex-col justify-center w-40 text-center border h-28 sm:w-64 border-stroke-on-white-light rounded-2xl'>
            <span className='giant_1 text-text-default'>$2.5k</span>
            <span className='medium_4 text-text-default'>Initial Investments</span>
          </div>
        </div>
        <p className='mb-12 text-center medium_2 mt-9 sm:mt-24'>
          Build a diversified portfolio of private AI investments
        </p>
        <SlideCarousel companies={aiCompanies} />
      </div>
    </>
  )
}

const VariantThree = () => {
  const { digitalAssetCompanies } = useSelector((state) => state.userSlice)
  return (
    <>
      <div className='flex flex-col items-center center'>
        <p className='large_1 mb-14 mt-12 sm:mt-20 max-w-60 sm:max-w-[100%] text-center'>
          The future of finance is here.
        </p>
        <img
          alt='Globe'
          src={images['globe']}
          className='max-w-[270px] sm:max-w-96'
        />
        <p className='large_1 mt-9 sm:max-w-[450px] max-w-[300px] text-center'>
          Ripple’s decentralized blockchain technology is now utilized on every
          continent in the world.¹
        </p>
        <p className='mt-24 mb-12 text-center medium_2'>
          Build a diversified portfolio of private digital assets investments
        </p>
        <SlideCarousel companies={digitalAssetCompanies} />
        <p className='mt-24 mb-5 sm:mt-8 small_1'>¹thecryptobasic.com, 2024</p>
      </div>
    </>
  )
}

const VariantFour = () => {
  const { digitalAssetCompanies } = useSelector((state) => state.userSlice)
  return (
    <>
      <div className='flex flex-col items-center center'>
        <p className='mt-12 mb-12 text-center large_1 sm:mt-20 max-w-60 sm:max-w-96 text-text-default'>
          The future of finance is here – sign up to explore
        </p>
        <div className='flex items-center bg-background-default h-28 w-[335px] rounded-[16px] p-5 border border-stroke-on-white-light'>
          <p className='mr-2 giant_1 text-text-default'>98%</p>
          <p className='text-left medium_2 text-text-default'>
            of institutional investors believe in digital assets
          </p>
        </div>
        <div className='flex items-center bg-background-default h-28 w-[335px] rounded-[16px] p-5 mt-6 border border-stroke-on-white-light'>
          <p className='mr-2 giant_1 text-text-default'>2/3</p>
          <p className='text-left medium_2 text-text-default'>
            are already invested in cryptocurrencies
          </p>
        </div>
        <p className='my-12 text-center medium_2 sm:mt-20 text-text-default'>
          Build a diversified portfolio of private digital assets investments
        </p>
        <SlideCarousel companies={digitalAssetCompanies} />
        <p className='medium_1 mt-12 sm:mt-24 mb-[119px] sm:max-w-[540px] text-text-default'>
          A 2024 Ernst & Young survey of 722 institutional investors including
          asset managers, asset owners, family offices, and hedge funds, reveals
          that 94% believe in the long-term viability of digital assets as an
          investment class. More than 2/3 are already invested in
          cryptocurrencies or digital assets through funds or direct
          investments.
        </p>
      </div>
    </>
  )
}

export { VariantOne, VariantTwo, VariantThree, VariantFour }
