import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import MfaModal from './Mfa/MfaModal'
import { PageLoading } from 'components'
import Button from '../Global/Button'
import PsdModal from './ChangePassword/PsdModal'
import SecurityItem from './partials/SecurityItem'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { addSecurityMfa, getSecurityPage, removeSecurityMfa } from 'slices/securitySlice'
import MessagingCarousel from 'components/Global/MessagingCarousel'

const mfas = [
  {
    qrCodeUrl: '',
    type: 'TOTP',
    userMfaId: null,
    value: '',
    verified: false
  },
  {
    qrCodeUrl: '',
    type: 'SMS',
    userMfaId: null,
    value: '',
    verified: false
  },
  {
    qrCodeUrl: '',
    type: 'EMAIL',
    userMfaId: null,
    value: '',
    verified: false
  }
]

const typeName = {
  TOTP: 'Authenticator App',
  SMS: 'SMS Text Message',
  EMAIL: 'Email Authentication'
}

const typeDescription = {
  TOTP: 'Time-based one-time 6-digit codes',
  SMS: 'SMS Authentication is only supported in certain countries',
  EMAIL: 'Email: '
}

const typeIcons = {
  TOTP: 'security_icon_auth_app',
  SMS: 'security_icon_text_msg',
  EMAIL: 'security_icon_email'
}

const Security = props => {
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState('')
  const [mfaTypes, setMfaTypes] = useState(mfas)
  const [userMfaId, setUserMfaId] = useState('')
  const [showPsdModal, setShowPsdModal] = useState(false)
  const dispatch = useDispatch()
  const { mfas: userMfas, currentMfa, email, mfaEnabled, pageLoading } = useSelector(state => state.securitySlice)
  const { settingsContentCards, isMixpanelExist } = useSelector(state => state.commonSlice)
  const history = useHistory()

  useEffect(() => {
    if (isMixpanelExist) {
      Mixpanel.track('View Account Settings Page')
    }
  }, [isMixpanelExist])

  useEffect(() => {
    // mapMfaArr()
    fetchGetSecurityPage()
    const type = props.match?.params?.mfaType
    const userMfaId = props.match?.params?.userMfaId
    if (type && userMfaId) {
      setShowModal(true)
      setType(type)
      setUserMfaId(userMfaId)
    }
  }, [])

  // Compares API response with default MFA to render verified/unverified MFA's
  useEffect(() => {
    if (userMfas && userMfas.length) {
      setMfaTypes([...userMfas])
      const mfaTypesArr = [...mfaTypes]
      const foundTypes = [...mfaTypesArr.map(a => userMfas.find(b => b.type === a.type) || a)]
      // hard-code mfa order to always show Authenticator - SMS - Email
      const auth = foundTypes.find(obj => obj.type === 'TOTP')
      const sms = foundTypes.find(obj => obj.type === 'SMS')
      const email = foundTypes.find(obj => obj.type === 'EMAIL')
      setMfaTypes([auth, sms, email])
    }
  }, [userMfas])

  const fetchGetSecurityPage = async () => {
    await dispatch(getSecurityPage())
  }

  const handleClick = async (type) => {
    let value = ''
    if (type === 'email') {
      value = email
    }
    setType(type)
    if (type === 'sms') {
      setShowModal(true)
      setType(type)
      setUserMfaId('e')
      history.push(`/settings/${type}/e/1`)
    } else {
      const response = await dispatch(addSecurityMfa({ type: type.toUpperCase(), value }))
      if (response?.payload?.userMfaId) {
        setUserMfaId(response?.payload?.userMfaId)
        setShowModal(true)
        history.push(`/settings/${type}/${response?.payload?.userMfaId}/1`)
      }
    }
  }

  const handleAddSMS = async (value) => {
    const response = await dispatch(addSecurityMfa({ type: 'SMS', value }))
    const id = response?.payload?.userMfaId
    if (id) {
      setUserMfaId(id)
      history.push(`/settings/sms/${id}/2`)
    }
  }

  const hideModal = () => {
    history.push('/settings')
    setShowModal(false)
    setType('')
    setUserMfaId('')
  }

  const handleRemoveMfa = async (userMfaId) => {
    await dispatch(removeSecurityMfa({ userMfaId }))
    await dispatch(getSecurityPage())
  }

  const renderMfaOptions = () => {
    return mfaTypes.map(m => {
      if (m.userMfaId && m.verified) {
        return (
          <SecurityItem key={m.type} option={m} name={typeName[m.type]} description={typeDescription[m.type]} icon={typeIcons[m.type]} email={email} handelClick={handleRemoveMfa} disabled={mfaTypes.filter(v => v.verified).length === 1} />
        )
      } else {
        return (
          <SecurityItem key={m.type} option={m} name={typeName[m.type]} description={typeDescription[m.type]} icon={typeIcons[m.type]} email={email} handelClick={handleClick} />
        )
      }
    })
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Settings')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Settings')} />
      <div className='page-container'>
        <div className='inner-container'>
          {settingsContentCards?.length ? (
            <div className='messaging-carousel-container'>
              <MessagingCarousel data={settingsContentCards} page='Settings' />
            </div>) : null}
          <h1>Settings</h1>
          <div className='border border-stroke-on-white-light box'>
            <div className='border-b medium_2 border-b-stroke-on-white-light box-title'>
              Account Settings
            </div>
            <div className='box-body'>
              <div className='border-b border-b-stroke-on-white-dark box-item centered'>
                <div>
                  <Button onClick={() => setShowPsdModal(true)}>Change Password</Button>
                  {showPsdModal && <PsdModal hideModal={() => setShowPsdModal(false)} />}
                </div>
              </div>
            </div>
          </div>
          {mfaEnabled &&
            <div className='border border-stroke-on-white-light box'>
              <div className='flex-col items-start justify-start px-0 py-4 medium_2 lg:px-6 lg:border-b border-stroke-on-white-light'>
              Multi-Factor Authentication
                <span className='mt-2 medium_1 text-text-default lg:mt-0'>At least one authentication method must be enabled.</span>
              </div>
              <div className='box-body'>
                {renderMfaOptions()}
              </div>
            </div>
          }
          <Link className='inline-block mt-12 text-text-hyperlink' to='/settings/delete-account'>Delete Account</Link>
          {showModal && type && <MfaModal hideModal={hideModal} type={type} userMfaId={userMfaId} mfaTypes={mfaTypes} currentMfa={currentMfa} handleAddSMS={handleAddSMS}/>}
        </div>
      </div>
    </>
  )
}

export default Security
