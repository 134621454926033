import { formatDecimal } from 'utils'
import { useSelector } from 'react-redux'
import { Row } from 'components'

const PlaceOrderHeader = ({ sharePrice }) => {
  const { details, linqtoImpliedValuation } = useSelector(state => state.investSlice)
  const { isSoldOut, promoFlag, impliedValuation } = details

  const showBlur =
  promoFlag === 'FULLY_SUBSCRIBED' ||
  (promoFlag === 'COMING_SOON' && (linqtoImpliedValuation === 'TBD' || !sharePrice))

  return (
    <div className='border shadow-none box border-stroke-on-white-light invest-order-box invest-order-header'>
      {isSoldOut &&
      <Row className='invest-order-row amounts'>
        <span className='medium_1'>Status</span>
        <span className='medium_1'>
          {promoFlag === 'COMING_SOON' ? 'Coming Soon' : 'Unavailable'}
        </span>
      </Row>
      }
      <Row className='invest-order-row amounts'>
        <span className='medium_1'>Implied Valuation</span>
        <span className='medium_1'>{(impliedValuation && !isSoldOut) || (promoFlag === 'COMING_SOON' && impliedValuation) ? linqtoImpliedValuation : 'TBD'}</span>
      </Row>
      <Row className='invest-order-row amounts'>
        <span className='medium_1 sub-header'>Share Price</span>
        <span className={`medium_2 ${showBlur && 'blured'}`}>{sharePrice ? formatDecimal(sharePrice) : '$0.00'}</span>
      </Row>
    </div>
  )
}

export default PlaceOrderHeader
