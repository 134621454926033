import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageLoading, Wrapper } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate, MOBILE_WIDTH_SIZE } from 'utils'
import InvestSection from './partials/InvestSection'
import { getInvestPage } from 'slices/investSlice'
import { useQuery } from 'hooks/useQuery'
import Button from 'components/Global/Button'
import { images } from 'assets'
import SearchCompaniesInput from './partials/SearchCompaniesInput'
import useWindowSize from 'hooks/useWindowSize'
import { CircleLoader } from 'components/Global/ImageWithLoader'
import LinqtoCapitalPlacementCopy from 'components/Global/LinqtoCapitalPlacementCopy'

const VerticalItem = ({item, onClose}) => (
  <div className='rounded-[100px] bg-background-default h-9 md:h-7 flex-[0_0_auto]' data-testid='selected-vertical'>
    <div className='bg-gradient-citron-turq rounded-[100px] p-0.5'>
      <span className='flex w-full bg-background-default rounded-[100px] px-3 body_1 text-text-default h-9 md:h-7 items-center'>
        {item} <Button onClick={() => onClose('')} className='ml-1 -mr-1' ariaLabel='Remove Vertical'><img src={images['remove-tag']} alt='Remove Vertical' /></Button>
      </span>
    </div>
  </div>
)

const SortSelect = ({sort, onSortChange, className }) => (
  <select
    value={sort}
    onChange={(e) => {onSortChange(e.target.value)}}
    className={`${className} bg-background-default border border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue`}
    aria-label='Sort companies by'
    data-testid='sort-select'
  >
    <option value='' >Sort by</option>
    <option value='NAME' >Name</option>
    <option value='VALUATION'>Valuation</option>
    <option value='DATE'>Date Added</option>
  </select>
)

const InvestList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const { width } = useWindowSize()

  const { companies, verticals, isRetailPurchaser, nonRetailCompanies, accreditedStatus } = useSelector(state => state.investSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  
  const [pageLoading, setPageLoading] = useState(true)
  const [vertical, setVertical] = useState(query.get('vertical') || '')
  const [sort, setSort] = useState(query.get('sort') || '')
  const [verticalSelectValue, setVerticalSelectValue] = useState('')
  const [searchResultLoading, setSearchResultLoading] = useState(false)

  const { RetailPurchaseAllowed } = featureFlags || {}
  const pageTitle = seoTitleTemplate('Invest')
  const pageDescription = 'Linqto is private investing made simple. Browse our product list to learn more about our offerings and to begin your journey in private equity investing.'
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const isRetailFlow = query.get('list') === 'retail' && RetailPurchaseAllowed && isRetailPurchaser && accreditedStatus !== 'ACCREDITED'
  
  useEffect(() => {
    (async () => {
      setSearchResultLoading(true)
      const res = await dispatch(getInvestPage({ vertical: vertical === 'all' ? '' : vertical, query: '', sort, firstRender: null }))
      const { meta } = res
      if (meta.requestStatus === 'fulfilled') {
        setPageLoading(false)
        setSearchResultLoading(false)
      }
    })()
  }, [vertical, sort])

  /**
   * Takes the selected verticals (sectors) and sort query 
   * and builds the needed products-list URL string
   * @function buildQueryURL
   * @param {String} sort - sort - the selected sort query (e.g., 'NAME', 'VALUATION', 'DATE')
   * @param {String} vertical - vertical - the selected vertical/sector
   * @returns - return url string
   */
  const buildQueryURL = (sort, vertical) => {
    let urlString = '/products-list'
    if ((sort && sort !== '') || vertical) {
      urlString += '?'
      // If sort exists, add it to the URL
      if (sort && sort !== '') {
        urlString += `sort=${sort}`
      }
      // If vertical exists, add it to the URL
      if (vertical && vertical !== '') {
        // Check if sort exists, if it does, add an ampersand
        if (sort) {
          urlString += '&'
        }
        // Add the vertical to the URL
        urlString += `vertical=${encodeURIComponent(vertical)}`
      }
    }
    return urlString
  }

  const showProductDetails = (item) => {   
    localStorage?.setItem('investFeedScroll', window.scrollY)
    history.push(`/products/${item?.urlName}`)
  }

  const onVerticalChange = (selectedVertical) => {
    setVertical(selectedVertical)
    window.history.replaceState(null, null, buildQueryURL(sort, selectedVertical))
    setVerticalSelectValue('')
  }

  const onSortChange = (selectedSort) => {
    setSort(selectedSort)
    window.history.replaceState(null, null, buildQueryURL(selectedSort, vertical))
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }

  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <Wrapper className='page-container invest-container'>
        <div className='inner-container'>
          <div className='container'>
            <div className='flex items-center mb-6' role='navigation' aria-label='Back to invest page'>
              <Button data-testid='back-btn' className='p-0 mr-6' onClick={() => history.push('/products')} ariaLabel='Back to invest page'><img src={images['back-circle']} alt='Back to invest page' /></Button>
              <h2 className='medium_4' role='heading' aria-level='1'>{isRetailFlow ? 'For Accredited Investors' : 'Companies'}</h2>
              {isRetailFlow && <SearchCompaniesInput />}
            </div>
            <div className='relative'>
              {!isRetailFlow && <div className='flex gap-2 mb-4 overflow-x-auto md:mb-8' role='group' aria-label='Filter and Sort Companies' style={{scrollbarWidth: 'none'}}>
                {vertical && vertical !== 'all' && <VerticalItem item={vertical} onClose={onVerticalChange} />}
                <select
                  onChange={(e) => {
                    onVerticalChange(e.target.value)
                  }}
                  value={verticalSelectValue}
                  aria-label='Filter by sector'
                  data-testid='sector-select' 
                  className='appearance-none rounded-[100px] bg-background-mid-gray body_1 hover:border-0 w-[110px] pr-0 h-10 md:h-8 border-0 text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue'>
                  <option value=''>Sectors</option>
                  <option value='all'>All Sectors</option>
                  { verticals && verticals.length > 0 && verticals.map((e, i) => <option value={e} key={`vertical-${i}`}>{e}</option>) }
                </select>
                {sort === '' ? <SortSelect sort={sort} onSortChange={onSortChange} className='appearance-none rounded-[100px] bg-background-mid-gray body_1 border-0 hover:border-0 w-[105px] pr-0 h-10 md:h-8'/> :
                // adding these divs to make the select box rounded with gradient border
                  <div className='rounded-[100px] bg-background-default h-10 md:h-8'>
                    <div className='bg-gradient-citron-turq rounded-[100px] p-[2px]'>
                      <div className='flex w-full bg-background-default rounded-[100px] pl-4 medium_2'>
                        <SortSelect sort={sort} onSortChange={onSortChange} className='appearance-none border-0 hover:border-0 w-[120px] p-0 rounded-[100px] text-sm h-9 md:h-7'/>
                      </div>
                    </div>
                  </div>
                }
                {!isMobile && <SearchCompaniesInput />}
              </div>}
              {isMobile && <SearchCompaniesInput />}
              {searchResultLoading && <div className='mt-20'><CircleLoader /></div>}
              {!searchResultLoading && <InvestSection title='' list={isRetailFlow? nonRetailCompanies : companies} listType='products' listStyle='list' onItemClick={showProductDetails} /> }
            </div>
          </div>
        </div>
      </Wrapper>
      <LinqtoCapitalPlacementCopy marginTop={0}/>
    </>
  )
}

export default InvestList
