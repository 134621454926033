import { Button } from 'components'
import { useHistory } from 'react-router-dom'
import { Mixpanel, Fullstory } from 'utils'

const SignUpLink = () => {
  const handleSignUp = () => {
    history.push('/signup')
    Mixpanel.track('Click Sign Up on Sign In Page')
    Fullstory.track('Click Sign Up on Sign In Page')
  }
  const history = useHistory()
  return (
    <span className='flex items-center justify-center mt-1 text-text-default'>
      Don&apos;t have an account?
      <Button onClick={handleSignUp} className='link text-text-hyperlink left-padded'>Sign Up</Button>
    </span>
  )
}

export default SignUpLink
