import { Modal, Button, TextInput, Tooltip } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/addFundsSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { getAddFundsPage, getPlaidLinkToken } from 'slices/walletSlice'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { Mixpanel } from 'utils'
import InsufficientFundsErrors from '../../FBOAddFunds/partials/InsufficientFundsErrors'
import { contentfulContentIds } from 'components/data'
import { getLabelContent, getTooltipContent, getImageContent } from 'utils/Contentful'
import { MAX_UPHOLD_LIMIT } from 'components/FBOAddFunds/FBOAddFunds'
import { getNumericDollar, formatDecimal } from 'utils'

const CONTENT_FIELDS = {
  ADD_FUNDS_ICON: 'addFundsIcon',
  ADD_FUNDS_TITLE: 'addFunds',
  DEPOSIT_AMOUNT: 'depositAmount',
  DEPOSIT_AMOUNT_TOOLTIP: 'depositAmountTooltip',
  FUNDING_SOURCE_LABEL: 'fundingSoruceLabel',
  REVIEW_BUTTON: 'reviewButton'
}

const AddFundsRedesign = ({ hideModal }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { contentfulData } = useSelector(state => state.commonSlice)
  const { achLimit } = useSelector(state => state.walletSlice)
  const query = location.search
  const entityId = qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showHover, setShowHover] = useState(false)
  // TODO hookup logic in next ticket
  //   const [showPlaidErrorModal, setShowPlaidErrorModal] = useState(false)
  const [showUnderReviewModal, setShowUnderReviewModal] = useState(false)
  const [showTopBanner, setShowTopBanner] = useState(null)
  const [upholdAPIError, setUpholdAPIError] = useState('')
  const [insufficientError, setInsufficientError] = useState(
    location?.state?.error === 'UPHOLD_INSUFFICIENT_FUNDS' || null
  )
  const [contentLabels, setContentLabels] = useState({
    addFundsIcon: '',
    addFunds: '',
    depositAmount: '',
    depositAmountTooltip: '',
    fundingSourceLabel: '',
    reviewButton: ''
  })

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    context: { achLimit: selectedAccount?.method === 'Uphold' ? MAX_UPHOLD_LIMIT : achLimit }
  })

  const {
    setValue,
    clearErrors,
    formState: { errors }
  } = methods

  useEffect(() => {
    if (!contentfulData?.length) return

    const addFundsRedesignContent = contentfulData.find(
      item => item.sys.id === contentfulContentIds.addFundsModal
    )

    const items = addFundsRedesignContent?.fields?.items || []
    const fetchContentLabels = async () => {
      const addFundsIcon = await getImageContent(CONTENT_FIELDS.ADD_FUNDS_ICON, items)
      const addFunds = getLabelContent(CONTENT_FIELDS.ADD_FUNDS_TITLE, items)
      const depositAmount = getLabelContent(CONTENT_FIELDS.DEPOSIT_AMOUNT, items)
      const depositAmountTooltip = getTooltipContent(
        CONTENT_FIELDS.DEPOSIT_AMOUNT_TOOLTIP,
        items,
        {},
        false
      )
      const reviewButton = getLabelContent(CONTENT_FIELDS.REVIEW_BUTTON, items)

      setContentLabels({
        addFundsIcon,
        addFunds,
        depositAmount,
        depositAmountTooltip,
        reviewButton
      })
    }

    fetchContentLabels()
  }, [contentfulData])

  const validatePriceValues = inputVal => {
    setValue('amount', formatDecimal(getNumericDollar(inputVal)))
  }

  useEffect(() => {
    dispatch(getAddFundsPage(entityId)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload.plaidLinkingAllowed) dispatch(getPlaidLinkToken(entityId))
        const isBankLinked =
          payload?.cashExternalAccounts?.filter(a => a.amlStatus === 'APPROVED').length > 0
            ? true
            : payload.cashExternalAccounts?.filter(a => a.amlStatus === 'NEEDS_REVIEW').length > 0
              ? 'Under Review'
              : false

        Mixpanel.track('View Add Funds Page', {
          'Uphold Linked': payload.hasUphold
            ? payload.upholdError
              ? 'Email Verification'
              : true
            : false,
          'Bank Account Linked': isBankLinked
        })
        if (payload && payload.hasUphold && sessionStorage.getItem('connectUpholdAddFunds')) {
          if (!payload.upholdError && !payload.upholdUnderReview) {
            setShowTopBanner({
              title: 'Uphold Successfully Linked',
              body: 'Your Uphold Account can now be used as a funding method.'
            })
            // TODO hookup logic in next ticket
            console.log(showTopBanner)
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Uphold' })
          }
          if (payload.upholdUnderReview) {
            setShowUnderReviewModal(true)
            // TODO hookup logic in next ticket
            console.log(showUnderReviewModal)
          }
          if (payload.upholdError) {
            setShowTopBanner({
              title: 'Almost there!',
              body: `Please check your email. ${payload.upholdError}`
            })
          }
          sessionStorage.removeItem('connectUpholdAddFunds')
        }
        if (insufficientError) {
          const upholdCards = payload.upholdCards
          setSelectedAccount({
            ...upholdCards.filter(a => a.id === location?.state?.cardId)[0],
            method: 'Uphold'
          })
          methods.reset({ amount: `$${location?.state?.amount}` })
          methods.setError('amount', {
            type: 'UPHOLD_INSUFFICIENT_FUNDS',
            message:
              'Insufficient funds. Change the amount to transfer or select a different funding source.'
          })
        }
      } else if (meta.requestStatus === 'rejected') {
        if (payload) {
          setUpholdAPIError(payload)
          // TODO update logic in next ticket
          console.log(upholdAPIError)
        }
      }
    })
  }, [])

  return (
    <Modal crossToClose hideModal={hideModal}>
      <div className='flex flex-col justify-center '>
        <FormProvider {...methods}>
          <img
            src={contentLabels?.addFundsIcon?.media?.url}
            alt={contentLabels?.addFundsIcon?.media?.title}
            className='h-12'
          />
          <p className='mb-12 large_1 mt-0.5'>{contentLabels?.addFunds}</p>
          <div className='self-center'>
            <p className='text-left'>
              {contentLabels?.depositAmount}
              <Tooltip
                header={contentLabels?.depositAmountTooltip?.tooltipTitle}
                content={contentLabels?.depositAmountTooltip?.tooltipBody}
                showHover={showHover}
                setShowHover={setShowHover}
              />
            </p>
            <TextInput
              className='w-[444px] self-center'
              name='amount'
              ariaLabel='Amount'
              placeholder='$0.00'
              onChange={() => {
                if (insufficientError) {
                  setInsufficientError(null)
                  clearErrors('amount')
                }
              }}
              onBlur={e => {
                validatePriceValues(e.target.value)
              }}
              handleKeyPress={e => {
                const invalidEntry =
                  (e.key !== '.' && Number.isNaN(Number(e.key))) ||
                  e.key === null ||
                  e.key === ' ' ||
                  e.key === '-'
                if (e.key === 'Enter') {
                  e.target.blur()
                } else if (invalidEntry) {
                  e.preventDefault()
                }
              }}
            />
          </div>
          {insufficientError && <InsufficientFundsErrors />}
        </FormProvider>
        <div className='flex items-center justify-center'>
          <Button
            customClass='max-w-[444px] w-full'
            onClick={() => history.push('/profile?tab=brokerage')}
            disabled={Object.keys(errors)?.length > 0}
          >
            {contentLabels?.reviewButton}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddFundsRedesign
