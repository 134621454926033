import { useEffect, useState } from 'react'
import 'rc-slider/assets/index.css'
import { Row, Tooltip, Button, PurchaseMessagingBanner} from 'components'
import { images } from 'assets'
import { formatDecimal, formatWholeNumber, getNumericDollar } from 'utils'
import InvestDetailOrderPill from './InvestDetailOrderPill'
import TextInput from 'components/Global/Inputs/TextInput'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import useCompanyShares from './useCompanyShares'

const InputPurchaseDesktop = ({ beginOrder, setShowingErrorBanner }) => {
  const history = useHistory()
  const methods = useForm({ mode: 'onBlur' })
  const { setValue, watch } = methods
  const amount = watch('investmentAmount')
  const amountDecimal = amount ? getNumericDollar(amount) : 0
  const { 
    companyShareData, sliderMinimum, companySharePrice, 
    isAccredited, isProfileComplete, showMinimumBanner, showMaxBanner, 
    companyErrorMessage, disableOrderButton, placeOrderButtonText  
  } = useCompanyShares(amountDecimal)
  const { shares, cost, pricePerShare } = companyShareData || {}

  const [showEstimatedSharesHover, setShowEstimatedSharesHover] = useState(false)
  const [showAccreditationHover, setShowAccreditationHover] = useState(false)
  
  // quick buy values are the minimum, 10k, and 25k
  const initialQuickBuyAmounts = [sliderMinimum, 10000, 25000]

  useEffect(() => {
    setShowingErrorBanner(showMaxBanner || showMinimumBanner || companyErrorMessage)
  }, [showMaxBanner, showMinimumBanner, companyErrorMessage])

  // Strips the $ and formats the value to a decimal
  const getFormattedAmount = (value) => {
    const dollarValue =  getNumericDollar(value)
    return formatDecimal(dollarValue)
  }

  const onChangeAmount = (value) => {
    setValue('investmentAmount', getFormattedAmount(value))
  }

  return (
    <>
    <Row className='flex items-center justify-between'>
      <FormProvider {...methods}>
        <span className='medium_1 mb-[6px]'>Investment Amount</span>
        <TextInput
          className='w-[120px]'
          name='investmentAmount'
          ariaLabel='Investment Amount'
          placeholder={formatDecimal(initialQuickBuyAmounts[0])}
          onBlur={(e) => onChangeAmount(e.target.value)}
          handleKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.target.blur()
            } else if (!/[0-9.$]/.test(e.key)) {
              e.preventDefault()
            }
          }}
        />
      </FormProvider>
      </Row>
      <Row className='flex justify-between mt-[15px] mb-[25px]'>
        <InvestDetailOrderPill onClick={() => onChangeAmount(sliderMinimum)} active={amountDecimal === initialQuickBuyAmounts[0]} amount={`$${sliderMinimum / 1000 + 'K'}`} />
        <InvestDetailOrderPill onClick={() => onChangeAmount(10000)} active={amountDecimal === initialQuickBuyAmounts[1]} amount='$10K' />
        <InvestDetailOrderPill onClick={() => onChangeAmount(25000)} active={amountDecimal === initialQuickBuyAmounts[2]} amount='$25K' />
      </Row>
      <div className='flex justify-between'>
        <p className='mb-5 medium_1'>Price Per Share</p>
        {/* Compare local share price to BE share price to show discount share pricing*/}
        {pricePerShare < companySharePrice
          ? (
            <div className='flex'>
              <div className='line-through medium_1 mr-1.5 text-text-secondary'>{formatDecimal(companySharePrice)}</div>
              <div className='medium_1'>{`${formatDecimal(pricePerShare)}`}</div>
            </div>
          )
          : ( <div className='medium_1'>{`${formatDecimal(companySharePrice)}`}</div>)
        }
      </div>
      <div className='mb-5 border-[.5px] border-stroke-on-white-light'/>
      <div className='flex justify-between'>
        <div className='mb-5 medium_1'>Estimated Shares
          <Tooltip
            header='Estimated Shares'
            content='Estimated shares adjusted to reflect whole shares and applied discounts.'
            showHover={showEstimatedSharesHover}
            setShowHover={setShowEstimatedSharesHover}
          />
        </div>
        <p className='medium_2'>{amountDecimal > 0 ? formatWholeNumber(shares) : 0}</p>
      </div>
      <PurchaseMessagingBanner isVisible={showMinimumBanner} color='red'>
        <div className='flex'>
          <img src={images.exclaim} alt='exclaim' className='mr-2 ' />
          <p className='body_1'>Minimum order is <span className='body_2'>{formatDecimal(sliderMinimum)}</span></p>
        </div>
      </PurchaseMessagingBanner>
      <PurchaseMessagingBanner isVisible={showMaxBanner} color='red' bannerPX={'px-2'}>
        <div className='flex'>
          <img src={images.exclaim} alt='exclaim' className='mr-1' />
          <p className='body_1'>Max. Order is {formatDecimal(cost)}</p>
        </div>
        <Button
          className='body_2' 
          onClick={() => onChangeAmount(cost)}
        >
          Enter Max.
        </Button>
      </PurchaseMessagingBanner>
      <PurchaseMessagingBanner isVisible={!!companyErrorMessage && !showMinimumBanner} color='red'>
        <div className='flex'>
          <img src={images.exclaim} alt='exclaim' className='mr-2 ' />
          <p className='body_1'>{companyErrorMessage}</p>
        </div>
      </PurchaseMessagingBanner>
      <PurchaseMessagingBanner isVisible={!isAccredited || !isProfileComplete} color='blue' >
        <div className='flex'>
          <img src={images.person} alt='person icon' className='mr-2 ' />
          {!isProfileComplete ? 
            <div className='flex'><div className='flex mr-0.5 body_1'>Complete your {' '}</div><Button onClick={() => history.push('/profile')} className='underline body_1'>investor profile.</Button></div>
            : <><div className='flex mr-0.5 body_1 '>Please {'  '} </div><Button onClick={() => history.push('/investor-status')} className='underline body_1'>verify your accreditation.</Button></>
          }
        </div>
        {(isProfileComplete && !isAccredited) &&
        <Tooltip
          top='top-0'
          header='Verify Accreditation'
          content='Regulations require you to be accredited to invest on Linqto.'
          showHover={showAccreditationHover}
          setShowHover={setShowAccreditationHover}
        />}
      </PurchaseMessagingBanner>
      <Button
        customClass='full-width'
        onClick={() => beginOrder({ cost, shares })}
        disabled={disableOrderButton}
      >
        {placeOrderButtonText}
      </Button>
    </>
  )
}
export default InputPurchaseDesktop