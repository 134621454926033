import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Mixpanel, formatWholeNumber, MOBILE_WIDTH_SIZE } from 'utils'
import { Button } from 'components'
import { useLDFlags } from 'utils/LaunchDarkly'
import useWindowSize from 'hooks/useWindowSize'

const InvestDetailSellButton = () => {
  const {
    details: { urlName, name, atsPoolEnabled }, ownedShares, canSellShares
  } = useSelector((state) => state.investSlice)
  const history = useHistory()
  const { inputPurchaseAmount } = useLDFlags(['inputPurchaseAmount'])
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE

  const handelSell = () => {
    Mixpanel.track('Click Sell on Product Page', { 'Company Name': name })
    if (atsPoolEnabled) {
      history.push(`/sell-order/create/${urlName}`)
    }
  }

  if (inputPurchaseAmount && isMobile) return null

  if (canSellShares && ownedShares > 0 && atsPoolEnabled) {
    return (
      <div className='invest-details-sell-container'>
        <div className={`box border border-stroke-on-white-light shadow-none invest-order-box ${inputPurchaseAmount ? 'max-w-[344px]' : ''}`}>
          <div className='text-text-default'>Shares Owned</div>
          <div className='text-text-default'>{formatWholeNumber(ownedShares)}</div>
          <Button className='link text-text-hyperlink' onClick={handelSell}>
            Sell
          </Button>
        </div>
      </div>
    )
  }
  return null
}
export default InvestDetailSellButton
