import { useHistory } from 'react-router-dom'
import { Button } from 'components'

const SignInLink = () => {
  const history = useHistory()
  return (
    <span className='flex items-center justify-center mt-1 text-text-default'>
      Already have an account?
      <Button onClick={() => history.push('/signin')} className='link text-text-hyperlink left-padded'>Sign In</Button>
    </span>
  )
}

export default SignInLink
