import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDrawerContent, useLDFlags } from 'utils';

const useCampaignVariation = (flag) => {
  const { campaignShowNewCompanyDatabricks } = useLDFlags([flag])
  const [showCampaign, setShowCampaign] = useState(false)
  const [contentfulDrawerData, setContentfulDrawerData] = useState(null)
  const { contentfulData = [] } = useSelector(state => state.commonSlice)
  const { userStage } = useSelector(state => state.userSlice)

  useEffect(() => {
    // TODO: update the conditon to either show drawer or carousel AND check the local storage key
    setShowCampaign(campaignShowNewCompanyDatabricks === 'drawer' && !localStorage.getItem(flag))
    const drawer = contentfulData.filter(item => item.sys.contentType.sys.id === 'marketingCard') || []
    const userDrawer = drawer.find(item => {
      const { metadata } = item || {}
      if (!metadata) return false
      const { tags } = metadata || {}
      return tags.find(tag => tag.sys.id.includes(userStage)) || {}
    })
    const { fields } = userDrawer || {}
    if (fields) {
      setContentfulDrawerData(getDrawerContent(fields))
    }
  }, [campaignShowNewCompanyDatabricks, contentfulData, userStage])

  const handleClose = () => {
    setShowCampaign(false)
    localStorage.setItem(flag, 'dismissed')
  }

  return [showCampaign, handleClose, contentfulDrawerData]
}

export default useCampaignVariation
