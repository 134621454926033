import BrazeConfig, { CONFIG_BRAZE } from 'utils/monitoring/Braze/Braze.config.js'
import ContentfulConfig, { CONFIG_CONTENTFUL } from 'utils/content/Contentful.config.js'

/**
 *
 * @type {LDClient | null}
 */
let configSource = null

let configValues = {}

/**
 * The implementation and values are a singleton in the application
 *
 * Not coding to an interface to avoid adding un-needed complexity right now.
 *
 * @param {import('launchdarkly_js_client_sdk').LDClient} configSourceImpl
 * @returns {Config}
 * @constructor
 */
function Config(configSourceImpl = null) {
  if (configSourceImpl) {
    configSource = configSourceImpl
  }
  return this
}

const CONFIG_TOKENS = [CONFIG_BRAZE, CONFIG_CONTENTFUL]

Config.prototype.init = async function () {
  const results = configSource.allFlags()
  const config = {}
  CONFIG_TOKENS.forEach(token => {
    config[token] = results[token] || null
  })

  Object.assign(configValues, config)
  Object.assign(this, configValues)
  return this
}

export default Config

/**
 * For convenience if the desired config is not yet accounted for in the prototype
 * @param token
 * @returns {*|null}
 */
Config.prototype.get = function (token) {
  const value = this[token] || null
  !value && console.warn(`Config value ${token} not found`)
  return value
}

Config.prototype.getBrazeConfig = function () {
  return new BrazeConfig(this)
}

Config.prototype.getContentfulConfig = function () {
  return new ContentfulConfig(this)
}
